import { jsPDF } from "jspdf";
import { Market } from "types";
import { contrastColor } from "contrast-color";

export function getTextDimensionsWithCharSpace(
  doc: jsPDF,
  text: string,
  charspace = 0
) {
  // 1. Get the basic dimensions without additional spacing
  const basicDimensions = doc.getTextDimensions(text);

  // Need to get all the spaces in the text
  const spaces = text.match(/\s/g) || [];

  // 2. Calculate the additional width due to character spacing
  const additionalWidth = (text.length - 2 - spaces.length) * charspace;

  // 3. Adjust the basic width by adding the additional width
  const totalWidth = basicDimensions.w + additionalWidth;

  // 4. Height remains unchanged as character spacing does not affect it
  const totalHeight = basicDimensions.h;

  return { width: totalWidth, height: totalHeight };
}

export function getMarketBranding(market: Market) {
  const marketId = market.id;

  let secondaryColourTextColor = contrastColor({
    bgColor: market.secondaryColour,
  });

  switch (marketId) {
    // case "hexham":
    //   return {
    //     textFillColor: "#0B2F45",
    //     tableHeaderFillColor: "#0B2F45",
    //     tableHeaderTextColor: "#ffffff",
    //     tableStripeFillColor: "#EFF2F4",
    //     tableStripeTextColor: "#000000",
    //   };

    default:
      return {
        dateFormat: "E, d MMM yyyy",
        textFillColor: market.secondaryColour ?? "#294F43",
        tableHeaderFillColor: market.secondaryColour ?? "#294F43",
        tableHeaderTextColor: secondaryColourTextColor ?? "#ffffff",
        tableStripeFillColor: "#EFEFEF",
        tableStripeTextColor: "#000000",
        legalText: `Title for the goods stated on this invoice shall remain with ${
          market.address?.company || "the market"
        } and shall not pass to the purchaser until the amount due under this invoice has been paid in full`,
      };
  }
}

export const PTSCONVERSION = 0.352777778;
