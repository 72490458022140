import { Timestamp as FSTimestamp } from "firebase-admin/firestore";
import { DocumentReference } from "firebase/firestore";

type Timestamp = FSTimestamp | any;

// dicates vat and commission applicatble to the line item
export type ClientType = "Seller" | "Buyer";

export type SuperType =
  | "Sheep"
  | "Goats"
  | "Cattle"
  | "Pigs"
  | "Horses"
  | "Deer"
  | "Poultry"
  | "Machinery"
  | "Antiques"
  | "Other";

export const SupportedSuperTypes: SuperType[] = [
  "Sheep",
  "Goats",
  "Cattle",
  "Pigs",
  "Horses",
  "Deer",
  "Poultry",
  "Machinery",
  "Antiques",
  "Other",
];
export const LivestockSuperTypes: SuperType[] = [
  "Sheep",
  "Goats",
  "Cattle",
  "Pigs",
  "Horses",
  "Deer",
  "Poultry",
];

// export type CattleSubtype =
//   | "Beef"
//   | "Dairy"
//   | "Heifer"
//   | "Bull"
//   | "Steer"
//   | "Calf"
//   | "Cow"
//   | "Other";
// export type SheepSubtype = "Ewe" | "Lamb" | "Ram" | "Other";
// export type GoatSubtype = "Ewe" | "Lamb" | "Buck" | "Other";
// export type PigSubtype = "Sow" | "Boar" | "Piglet" | "Other";
// export type HorseSubtype = "Mare" | "Foal" | "Stallion" | "Other";
// export type DeerSubtype = "Hind" | "Fawn" | "Stag" | "Other";
// export type PoultrySubtype = "Hen" | "Cockerel" | "Pullet" | "Other";
// export type MachinerySubtype = "Tractor" | "Other";
// export type AntiquesSubtype = "Other";

// export type ProductSubtype =
//   | CattleSubtype
//   | SheepSubtype
//   | GoatSubtype
//   | PigSubtype
//   | HorseSubtype
//   | DeerSubtype
//   | PoultrySubtype
//   | MachinerySubtype
//   | AntiquesSubtype;

export type Currency = "GBP" | "EUR" | "USD";
export const SupportedCurrencyCodes: Currency[] = ["GBP", "EUR", "USD"];
//  ISO 3166-2:GB
export type SupportedCountryCode =
  | "GB-ENG"
  | "GB-SCT"
  | "GB-WLS"
  | "GB-NIR"
  | "IE";
export type CurrenciesWithGuinea = Currency | "GUINEA-GBP";

export type UnitOfSale =
  | "Per KG"
  | "Per Item" /* aka 'per head'*/
  | "Per Lot"
  | "Per 1000KG";
export const SupportedUnitsOfSale: UnitOfSale[] = [
  "Per KG",
  "Per Item",
  "Per Lot",
  "Per 1000KG",
];
export type PaymentMethod =
  | "BACS"
  | "Cheque"
  | "BankTransfer"
  | "Cash"
  | "Card"
  | "Credit"; // contra

export type PayoutMethod = "BACS" | "Cheque" | "Cash";

export const SupportedPaymentMethods: PaymentMethod[] = [
  "BACS",
  "Cheque",
  "BankTransfer",
  "Cash",
  "Card",
  "Credit",
];

export interface Market {
  id: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;

  name: string;
  description: string;

  logo: string;
  primaryColour: string;
  secondaryColour: string;

  countryCode: SupportedCountryCode;

  address: Address;

  telephone?: string;
  vatNumber?: string;
  email?: string;

  movementLocationNumbers?: {
    number: string;
    type: "CPH" | "Herd Number" | "Flock Number" | "Other";
    // the issuing country that the holding number is valid for
    createdAt: Timestamp;
  }[];

  // Displayed on the invoice and other documents
  paymentInstructions?: string;

  // --/ settings
}

export interface SettingsMarketDefaults {
  updatedAt: Timestamp;
  updatedBy: string | null;
  marketId: string;

  name: string;
  description: string;
  address: Address;
  defaultCurrency: Currency;
  defaultPayoutPreference: PayoutMethod;
  defaultUnitOfSale: UnitOfSale; // the fallback if the product code doesn't have one

  defaultSuperType?: SuperType | null;

  lotDescriptionTemplateMap?: {
    default: string | undefined;
    [supertype: string]: string | undefined;
  };

  rostrumAttributesMap?: {
    default: string[] | undefined;
    [supertype: string]: string[] | undefined;
  };

  // When printing all docs are not selected by default
  // You can override that here for whole classes of supertypes
  // Add the ID to deselect it when printing. This can be overridden product code
  // the most specific setting will be used
  selectDocumentIdsMap: {
    [supertype: string]: string[];
  };
}

export interface SettingsProductCodes {
  [code: string]: ProductCodeConfiguration;
}

export interface SettingsTaxRates {
  [id: string]: TaxRate;
}

export interface SettingsAdjustmentsConfiguration {
  [id: string]: AdjustmentsConfiguration;
}

export interface SettingsAccessories {
  [id: string]: Accessory;
  updatedAt: Timestamp;
}

export interface SettingsPrinters {
  [id: string]: Printer;
  updatedAt: Timestamp;
  lastPrintedAt: Timestamp;
}

export type DeviceType = "globeWeigh" | "allflexRaceReader";

export interface Accessory {
  deviceId: string;
  deviceType: DeviceType;
  friendlyName: string;
  endOfMessagePattern: string;
  host: string;
  isEnabled: boolean;
  port: number;
  regex: string;
  saleProfileId: string;
  timeoutMs: number;
  updatedAt: Timestamp;
  createdAt: Timestamp;
}

/**
 * @description Configuration for the printer
 */
export interface Printer {
  id: string; // our unique id for the printer may include Tray at end if multiple trays
  createdAt: Timestamp;
  updatedAt: Timestamp;

  /**
   * @description The name of the printer. Its the same as the id
   */
  name: string; // name of the printer which is used by pdf-to-printer
  friendlyName: string; // friendly name e.g. Printer In Office
  chequePrinter?: boolean;
  /**
   * The next cheque number to use when printing a cheque
   */
  nextChequeNumber?: number;
  lastPrintedAt?: Timestamp;
  lastPrinterMessage?: string;
}

/**
 * @description The print job that is sent to the printer
 */
export interface PrintJob {
  id: string;
  createdAt: Timestamp;
  url: string; // the url to print
  deviceId: string; // the device Id which can include the tray number e.g. Brother HL-L2340D series Printer Tray 1
  copies: number;
  side?: "duplex" | "simplex";
  //tray?: string; We use printer ID to determine the tray from a list from the printers. And decode it in pushing to the puck in from pub/sub function
  isPrinted?: boolean;
  printedAt?: Timestamp;
  message?: string;
  isCheque?: boolean;
  // if printing out a cheque, this is the payout to update with the cheque number
  chequePayoutId?: string;
}
// in global studio settings
export interface SettingsGlobalAttributes {
  [id: string]: AttributeDefinition;
}

export type AttributeValueType = string | number | boolean | Timestamp | Media;

// The part to identify which applications are enable for this market. e.g. BCMS / LIS / APHIS / SAGE etc
// Theses market integrations configs will only be used if the sale doesn't provide it's own
export interface ExternalAppConfig {
  createdAt: Timestamp;
  updatedAt: Timestamp;

  id: string; // must be unique
  name: string;
  version: string;
  description: string;
  icon: string;
  url: string;
  author: string;

  // action name to url mapping
  actions: {
    name: string;
    url: string;
  }[];

  webhooks: {
    url: string;
    triggers: WebhookEventName[];
  }[];

  // User configurable settings for the app. Appears in the app settings page.
  parameterConfig: {
    id: string;
    type: "string" | "number" | "boolean" | "string[]" | "password";
    label: string;
    description?: string;
    required?: boolean;
    defaultValue?: string | number | boolean | string[];
  }[];

  // -- other data
  parameterValues: {
    [key: string]: string | number | boolean | string[];
  };
}

/****
 * Used to grant a user access to a market.
 * Each member of staff should have their own.
 */
export interface MemberSharingConfiguration {
  uid: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;

  displayName?: string;
  photoURL?: string;

  marketId: string;
  // only one owner per market.
  role: "Owner" | "Admin" | "Editor";
}

export interface Sale {
  id: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  updatedBy?: string | null;

  startsAt: Timestamp;

  closedAt?: Timestamp | null;

  // The owner of the sale
  marketId: string;

  // "Weekly Dairy Sale" / "Fat Lambs, Hoggets & Fat Ewes" etc
  name: string;
  description?: string | null;
  image?: string | null;

  /**
   * The location of the sale. This could be a physical location Ring 1 / Ring 2 / Farm Address etc
   */
  location?: string | null;

  recurring?: null | "Weekly" | "Bi-weekly" | "Monthly";

  defaultProductCode: string;

  /**
   * Default attributes values for any new lot item
   */
  attributeDefaults?: {
    [attributekey: string]: AttributeValueType;
  };

  /**
   * the codes that are allowed for the sale
   */
  availableProductCodes: string[];

  /**
   * the supertypes from the product codes. e..g Cattle, Sheep, Pigs etc
   */
  superTypes: SuperType[];

  // Either the string ID for a known item attibute (begins with an @) or a custom attribute
  attributeSet: AttributeDefinition[];

  // Not all attributes are valid for all products
  attributeSetByProductCode: {
    [code: string]: string[];
  };

  // The ID of the group of lots that are currently being sold (for a live sale)
  currentLotGroup?: string | null;
  // The ID of the group the clerk has queued up next
  nextLotGroup?: string | null;

  /**
   * Auto queue the next lot when the current lot is sold in rostrum
   */
  autoQueue?: boolean;

  /**
   * The sale profile ID to use for this sale. Which is used to find settings specific devices and printers etc.
   */
  saleProfileId?: string | null;

  /**
   * Show the display board
   */
  displayBoard?: boolean;
  /// folllow on sale ? TBD
}

export interface SaleFromSearch
  extends Omit<Omit<Omit<Sale, "createdAt">, "updatedAt">, "startsAt"> {
  createdAt: number;
  updatedAt: number;
  startsAt: number;
}

export type LotSaleStatus = "Sold" | "Unsold" | "Rerun" | "Resold" | null;

export interface Lot {
  id: string; // unique for the sale? Could be saleId + lotNumber
  saleId: string;
  marketId: string;

  createdAt: Timestamp;
  updatedAt: Timestamp;
  updatedBy?: string | null;

  // the item code of the lot. This is used to determine the commission rate and the display board
  // a lot can only contain items with the same product code (you can think of it as a product type)
  productCode: string;
  // / Set by a cloud function
  superType?: SuperType;
  // subtype?: ProductSubtype;
  isLivestock?: boolean;
  // \ Set by a cloud function

  attributes: {
    [key: string]: AttributeValueType;
  };

  // if present here than the attribute is loading in
  // remove to signify it has loaded
  loadingAttributes?: {
    [attributePath: string]: {
      id: string;
      // time loading began
      startAt: Timestamp;
      // the path to the attribute (may be on an item)
      path: string;
    };
  };

  itemMap: {
    [itemId: string]: LotItem;
  };

  sellerCasual?: string | null;
  sellerCustomerId: string | null;
  seller?: ShortCustomerDetails;

  lotNumber?: string | null;
  // The sort order of the lot within the sale
  index: number;
  // More than one lot can be sold in the ring at once. This groups the lots. Convention to use the earliest lot number in the group as the group ID
  group?: string;

  // Free text description of the lot. Intended to be customer facing.
  remarks?: string;

  // free text notes, not customer facing possible not needed.
  notes?: string;

  // Sale config
  currency: CurrenciesWithGuinea;

  unitOfSale: UnitOfSale;

  // --- Sale record

  unitPriceInCents?: number;

  // This could be any free text that the clerk input on the rostrum. It can be thought of as a note for someone to look up the actual buyer later
  buyerCasual?: string | null;
  buyerCustomerId: string | null;
  buyer?: ShortCustomerDetails | null;

  // The time the lot entered and left the ring
  startedAt?: Timestamp;
  endedAt?: Timestamp;

  // must be set, the null used to find records that have not been invoiced
  sellerInvoiceId: string | null;
  sellerInvoiceNumber?: number | undefined | null;
  // must be set, used to find records that have not been invoiced
  buyerInvoiceId: string | null;
  buyerInvoiceNumber?: number | undefined | null;

  // If an invoice is voided, we keep a record of the ID here
  voidedSellerInvoiceIds?: string[];
  voidedBuyerInvoiceIds?: string[];

  // in the case when a lot is unsold.
  // The seller gets and invoice but the buyer does not.
  saleStatus?: LotSaleStatus;
  // ignore the result of this lot. Likely because rerun or resold
  void?: boolean;
  // if this lot was created from a rerun or resell, this is the ID of the original lot
  originalLot?: string;

  // -- /Sale record

  metadata: {
    [key: string]: any;
  };

  // These are controlled by a cloud function
  generated?: LotGeneratedValues;

  // Issues with a lot can be raised asyncronously and resolved later.
  issues?: {
    [issueId: string]: LotIssue;
  };

  resolvedIssues?: {
    [issueId: string]: LotIssue;
  };

  /***
   * Default values for any new lot item
   */
  itemAttributeDefaults?: {
    [attributekey: string]: AttributeValueType;
  };
}

type LotIssueCode =
  | "unknown-attribute" // attribute ID is invalid or not setup for this sale
  | "validation-error" // serverside validation error
  | "staff-flagged"; // a staff member has raised an issue with the lot that needs to be resovled

export interface LotIssue {
  id: string;
  // optional. If the issue is specific to an item then this is the ID of the item
  itemId?: string | null;
  // optional. The path to the parameter that caused the issue
  path?: string | null;
  code: LotIssueCode;
  // The issue description
  description: string;
  // The issue severity
  severity: "error" | "warning" | "info";
  createdAt: Timestamp;
  // Who created the issue
  createdBy: string;
  // If there is an approval required for this lo
  blockCheckout: boolean;
}

export interface LotWithItemsAsArray extends Omit<Lot, "itemMap"> {
  items: LotItem[];
}

export interface LotGeneratedValues {
  // The total value of the lot in cents
  totalValueInCents?: number | null;

  // a description we can use in the cart and on the invoice
  description?: string | null;

  // We carry these through to the invoice as averages
  pricePerKiloInCents?: number | null;
  averageWeightKg?: number | null;
  pricePerItemInCents?: number | null;

  countOfItems?: number | null;
}

// To save a lookup to the customer document we store a copy of the customer details in the lot taken at the time it was added
// Whilst the customer may change their details later it's actually a useful feature not to go back and update the lot with the change
export interface ShortCustomerDetails {
  // true if the customer record has been found and copied in
  // false if the customer could not be found
  isSet: boolean;

  id?: string;
  copiedInAt?: Timestamp;
  accountNumber?: string;
  displayName?: string;

  // unsued on everything but the market
  avatar?: string | null;
}

// note the item does not have it's own document in firestore. It exists inside the Lot
export interface LotItem {
  id: string; // unique ID but it wont be created by firestore

  createdAt: Timestamp;
  updatedAt: Timestamp;
  updatedBy?: string | null;
  index: number;

  attributes: {
    [key: string]: AttributeValueType;
  };

  notes: {
    id: string;
    text: string;
    createdAt: Timestamp;
  }[];

  metadata: {
    // Interesting things to add here could be:
    //  - time the item was scanned in
    //  - photos of the entry forms
    //  - links to clips generated
    [key: string]: string | number | boolean | Timestamp | Media;
  };
}

/***
 * A market may offer other products and services. This is a way to track those.
 * They simply create a line item on the invoice
 *
 *  /settings/products
 */
export interface Product {
  id: string;

  createdAt: Timestamp;
  updatedAt: Timestamp;
  marketId: string;

  name: string;
  taxRateId: string;
  defaultUnitPriceInCents: number;

  unitPriceIncludesVat?: boolean;

  // The type of client this config applies too. If empty applies to all client types
  applyToClients: ClientType[];

  // line items with the same code are given a subtotal for the group on the invoice
  subtotalGroup?: SubtotalGroups | null | undefined;

  // true if this product supports routing this money to another customer (say a dealer or haulier)
  passThroughFundsToAnotherCustomer?: boolean;

  // Is the product enabled
  isEnabled?: boolean;

  metadata: {
    [key: string]: string | number | boolean | Timestamp | Media;
  };
}

/***
 * When a product is added to a cart it becomes a CartProductItem
 *
 *
 * markets/ballymena/carts/{userId}
 *
 *  {id: CartItem}
 */
export interface CartItem {
  id: string; // unique ID but it wont be created by firestore

  createdAt: Timestamp;
  updatedAt: Timestamp;
  updatedBy?: string | null;

  marketId: string;
  customerId: string;

  // The ID of the product
  productId: string;

  clientType: ClientType;
  // The quantity of the product
  quantity: number;

  // The total price of the product
  unitPriceInCents: number;

  // If the product supports routing this money to another customer (say a dealer or haulier)
  // then this is the ID of the customer to send the funds to
  passthroughFundsToCustomerId?: string | null;

  generated?: {
    totalInCents: number;
  };
}

/***
 * Items have codes. And those codes are used to apply the correct commission rates, report movements etc.
 */
export interface ProductCodeConfiguration {
  // Unique for the market, is the ID for the document
  code: string;

  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  updatedBy?: string | null;

  // Integrations will use this for determining if this item needs to have movements reported etc
  superType: SuperType;

  // subtype?: ProductSubtype;

  description: string;

  // Name for the icon to use in the UI
  icon?: string;

  // A set of expected attributes needed for the catalogue
  // attributes?: string[];

  metadata: {
    [key: string]: string | number | boolean | Timestamp | Media;
  };

  // -- invoices
  // - the following generate line items for the invoice.
  // - They can be removed or changed manually by the user

  // id for a TaxRate record
  // Users shoudl be able to override this on the invoice later
  // e.g. people may have different tax rates if they live in another country
  taxRateId: string;

  // groups up on the subtotal lines on the invoice
  // leave null if grouping isn't needed
  subtotalGroup?: SubtotalGroups | null;

  // extra charges to apply on the invoice (e.g. commission, levies etc)
  // IDs for AdjustmentsConfiguration records
  adjustmentIds: string[];

  // If the price is inclusive of VAT
  // We need to for IE as it is assumed the hammer price includes VAT
  // default false
  unitPriceIncludesVat?: boolean;

  totalExVatRebatePecentage?: number;

  // These carry over to the lots
  defaultCurrency?: CurrenciesWithGuinea; // if empty, defaults to market currency
  defaultUnitOfSale: UnitOfSale;

  // if provided, the lot will default to this product code when created
  // if the number is within this range
  defaultLotRange?: {
    min: number;
    max: number;
  };

  // These are copied over to new lots and lot items when created
  // item level attributes are prefixed "@"
  attributeDefaults?: {
    [attributekey: string]: AttributeValueType;
  };

  // A handlebars template to use for the description on the invoice
  lotDescriptionTemplate?: string;

  // The attributes we want editable on the rostrum active page
  rostrumAttributes?: string[];

  // When printing all docs are not selected by default
  // You can override that here for lots of this product code
  selectDocumentIds?: string[] | null;
}

/***
 * How the products are stored in settings
 */
export interface ProductConfiguration {
  [id: string]: Product;
  updatedAt: Timestamp;
}

/***
 * A market may offer other products and services. This is a way to track those.
 * They simply create a line item on the invoice
 */
export interface Product {
  id: string;

  createdAt: Timestamp;
  updatedAt: Timestamp;
  marketId: string;

  name: string;
  taxRateId: string;
  defaultUnitPriceInCents: number;

  unitPriceIncludesVat?: boolean;

  // The type of client this config applies too. If empty applies to all client types
  applyToClients: ClientType[];

  // line items with the same code are given a subtotal for the group on the invoice
  subtotalGroup?: SubtotalGroups | null | undefined;

  // true if this product supports routing this money to another customer (say a dealer or haulier)
  passThroughFundsToAnotherCustomer?: boolean;

  // if this product relates to a lot then this is the lot ID
  // e.g. dealer charge for a lot
  relatedTo?: { lotId: string; saleId: string } | null;

  // if true, and a relatedTo is set, then the funds will be added to the lot total on the invoice
  obfusicate?: boolean;

  metadata: {
    [key: string]: string | number | boolean | Timestamp | Media;
  };
}

export interface Cart {
  updatedAt: Timestamp;
  itemsById: {
    [itemId: string]: CartItem;
  };
}

export interface EmailWrapper {
  email: string;
  isVerified: boolean;
  createdAt: Timestamp;
}

export interface PhoneNumberWrapper {
  phoneNumber: string;
  isVerified: boolean;
  createdAt: Timestamp;
}

export interface AddressWrapper {
  address: Address;
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  updatedBy?: string | null;
}

export interface FarmAssurances {
  createdAt: Timestamp;
  updatedAt?: Timestamp;
  updatedBy?: string | null;
  memberReference: string;
  expiryDate: Timestamp;

  //   TODO need the full list of scopes that Red Tractor use
  scope?: "Beef" | "Dairy" | "Pork" | "Sheep" | "Other";
}

export type BankDetails = {
  accountName?: string; // Optional used to over ride the name of the account holder
} & (
  | { accountNumber: string; sortCode: string; IBAN?: never } // Either accountNumber and sortCode without IBAN
  | { IBAN: string; accountNumber?: never; sortCode?: never }
); // Or just IBAN without accountNumber and sortCode

/**
 * Used to store the bank details for a market
 */
export type MarketBankDetails = {
  name: string; // The bank account owner
  bankId: string; // virgin/hsbc etc
  address: Address;
} & (
  | { accountNumber: string; sortCode: string; IBAN?: never } // Either accountNumber and sortCode without IBAN
  | { IBAN: string; accountNumber?: never; sortCode?: never }
); // Or just IBAN without accountNumber and sortCode

export interface Customer {
  id: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;

  updatedBy?: string | null;

  // The market this customer belongs to
  marketId: string;

  // Every market has one customer to represent themselves. If this it true this record is that account.
  // Another way to tell is that the ID for this document will be identical to the market ID
  isMarketAccount?: boolean;

  // The user's "account" with the market. Useful for quick look up + showing on invoices
  accountNumber?: string;
  // A unique number that can be used to identify the customer.
  // Possible that it's reset as soon as after each sale
  bidderNumber?: number | null;

  firstName?: string | null;
  lastName?: string | null;

  tradingName?: string | null;

  email?: EmailWrapper;
  phoneNumber?: PhoneNumberWrapper;
  otherPhoneNumbers?: PhoneNumberWrapper[];

  address: AddressWrapper;
  otherAddresses: {
    [addressId: string]: AddressWrapper;
  };

  photoURL?: string | null;

  payoutPreference?: PayoutMethod;
  bankDetails?: BankDetails;

  // Attributes to add to the lot when the customer is the seller/buyer
  // Here we have an array of attribute values. The most latest used is added to the
  // 0 position in the array. The other values could be shown in a dropdown for the user to select
  attributeDefaultsBuyer?: {
    [attributekey: string]: AttributeValueType[];
  };
  attributeDefaultsSeller?: {
    [attributekey: string]: AttributeValueType[];
  };

  // If true any debt on the account will be paid down first before paying out out a credit balance (cheque/BACS etc)
  // defaults to true. Some markets call this "contra"
  preferSettleDebtsFirst?: boolean;

  // People may or may not have VAT numbers. This will affect the tax calculation on their bill.
  // the VAT number is used to determine the country and has prefix GB, IE, DE etc. for specific countries
  vatNumber?: string;

  metadata: {
    [key: string]: string | number | boolean | Timestamp | Media;
  };

  // If at any point this customer has bought or sold items in the market
  lastItemPurchaseDate?: Timestamp;
  lastItemSaleDate?: Timestamp;
  hasPurchasedItems?: boolean;
  hasSoldItems?: boolean;

  // Notes
  notes?: string;
}

// All Timestamps are replaced with a number
export interface CustomerFromSearch
  extends Omit<
    Customer,
    "createdAt" | "updatedAt" | "lastItemPurchaseDate" | "lastItemSaleDate"
  > {
  createdAt: number;
  updatedAt: number;

  // If at any point this customer has bought or sold items in the market
  lastItemPurchaseDate?: number;
  lastItemSaleDate?: number;
}

export interface Address {
  id: string;
  nickname?: string;
  company?: string;
  firstName?: string;
  lastName?: string;
  address1: string;
  address2?: string;
  city: string;
  province?: string;
  zip: string;
  // ISO Country Code. Must include the sub-region if applicable (e.g. GB-ENG, GB-WLS, GB-NIR, GB-SCT)
  // this is the part that is needed for calculating tax on the invoice
  country: string;
}

interface ImageThumbnail {
  url: string;
  width: number;
  height: number;
}

export interface Media {
  id: string;

  url: string;
  type: "image/jpeg" | "image/png" | "video/mp4" | "application/pdf";
  filename: string;
  thumbnails?: {
    small?: ImageThumbnail;
    medium?: ImageThumbnail;
    large?: ImageThumbnail;
  };
}

//// --- Report Headers --- ////

export type MarketReportHeaders =
  | "grossServices"
  | "vatOnServices"
  | "grossGoods"
  | "vatOnGoods"
  | "netTotal"
  | "contra";

// --- Invoices ---
/**
 * Content types which will denote the position of the data on the invoice wihth out with a cheque
 */

// a type used to define the position of the fields on the cheque
export type FieldPositions = {
  id: ChequeField | InvoiceField;
  x: number;
  y: number;
};

export type InvoiceField =
  | "invoiceNumber"
  | "invoiceDate"
  | "description"
  | "lotNumber"
  | "quantity"
  | "weight"
  | "unitPrice"
  | "grossLineTotal"
  | "accountRef"
  | "accountName"
  | "businessAddress"
  | "customerAddress"
  | "lineItemsStartLine" // the line that table items start at
  | "footerBoundaryLine" // this marks the line of the cheque break if used
  | "vatNumber"
  | "saleName"
  | "subTotal" // the column of subtotals e.g. vat, livestock etc
  | "subTotalTable" // the table of subtotals e.g. vat, livestock etc";
  | "tagList"
  | "tagNumber";

// a list of fields that can be used to generate a cheque
export type ChequeField =
  | "chequeDate"
  | "chequeNumber"
  | "payee"
  | "amount"
  | "amountInWords"
  | "hunderdsOfThousands"
  | "tensOfThousands"
  | "thousands"
  | "hundreds"
  | "tens"
  | "units";

export type SubtotalGroups = "Commission" | string;

export type AdjustmentTotalTarget =
  | "Per Invoice"
  | "Total - finalTotalInCents"
  | "Total - lotTotalLessCommissionInCentsExVat";

export type AdjustmentTarget =
  | UnitOfSale
  // Apply after the totals have been calculated but before any other total adjustments
  | AdjustmentTotalTarget;

/***
 * Commission is added as a line item. Can also be used for levies etc
 */
export interface AdjustmentsConfiguration {
  id: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  updatedBy?: string | null;

  // a friendly name for the adjustment to be used in the UI
  name?: string | null;

  // // How the item appears on the invoice
  description?: string | null;

  // The type of client this config applies too. If empty applies to all client types
  applyToClients: ClientType[];

  // line items with the same code are given a subtotal for the group on the invoice
  subtotalGroup?: SubtotalGroups | null | undefined;

  // this dictates how the money is moved through the ledger
  // commission is an income for the market
  // a levy would be a "liability" as becomes a liability to be paid off later as an expense
  // "vat-rebate" will (eventually) move the money back into the VAT account
  type: "commission" | "levy" | "vat-rebate";

  // Selects where this commission config applies
  filter?: {
    // apply only to item with this currency
    currency?: CurrenciesWithGuinea | null;

    // Apply only between the min and max quantity range (inclusive).
    quantity?: {
      // default zero
      min?: number;
      // default Infinity
      max?: number;
    } | null;

    // Apply only between a min and max per item price
    unitPrice?: {
      // default zero
      minInCents?: number;
      // default Infinity
      maxInCents?: number;
    } | null;

    totalPrice?: {
      // default zero
      minInCents?: number;
      // default Infinity
      maxInCents?: number;
    } | null;

    // set true to apply this adjustment if a customer is vat registered in any tax region
    // false to apply if they are not vat registered
    // leave undefined or null to apply to all customers
    customerIsVATRegistered?: boolean | null;
    // set true to apply this adjustment if the customer is in the same tax region as the market
    // false to apply if they are not in the same tax region
    // leave undefined or null to apply to all customers
    customerWithinTaxRegion?: boolean | null;

    // Will apply if a lot or any items has the attribute key / value pair
    // e.g. "onlineBuyer" : true
    // note we only allow the simple attribute values
    attribute?: {
      key: string;
      // use null to filter for null or undefined values
      value: string | number | boolean | null;
    } | null;
  };

  // The ID of the tax rate that is applied to this adjustment
  taxRateId: string;

  adjustment: Adjustment & { target: AdjustmentTarget };
}

interface Adjustment {
  percentage?: {
    // A value between 0-1.
    value?: number;
    // A minimum value the commision can be
    floorInCents?: number;
    // A cap for the heighest value a commision can be
    ceilingInCents?: number;
  } | null;

  // A single amount that is added if the filter applies.
  // Could be used for entry fees but likely need to seperate out the entry fee from the commission
  fixedAmountInCents?: number | null;
}

export interface LineItemAdjustmentConfiguration
  extends AdjustmentsConfiguration {
  adjustment: Adjustment & { target: UnitOfSale };
}

// specifically for targeting invoice totals
export interface InvoiceTotalAdjustmentConfiguration
  extends AdjustmentsConfiguration {
  adjustment: Adjustment & { target: AdjustmentTotalTarget };
}

/***
 * Tax config is used to generate the invoice but the user can still edit the invoice, overriding the final values.
 */
export interface TaxRate {
  id: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  marketId: string;

  // description of the tax shown on the invoice
  description: string;

  // line items with the same code are given a subtotal for the group on the invoice
  // e.g. "Tax on Services" or "VAT"
  taxSubtotalGroup?: string | null;

  // A value between 0-1. This is the tax rate that will be applied
  percentage: number;

  // If true then this tax rate will override others if
  //   customerIsVATRegistered === true
  //   customerWithinTaxRegion === false
  //
  // (see the createDraftInvoice function)
  // There can be only one export tax rate. Validate in the UI.
  isExportTaxRate?: boolean;
}

// A payment into the market
export interface Payment {
  id: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  updatedBy?: string;

  transactionDate: Timestamp;

  marketId: string;
  // the customer who made the payment
  customerId: string;
  method: PaymentMethod;

  // note currency is always assumed to be the market currency
  amountInCents: number;
  // Should be what will appear on the mart's bank statement
  // for a cheque this is the cheque number
  // for bacs this is the reference number
  // for card this is the transaction reference
  // not always present (e.g. cash)
  reference: string | null;

  status: "complete" | "void";

  voidedAt?: Timestamp | null;
  voidedBy?: string | null;
  voidReason?: string | null;

  // Invoices that this payment went toward
  invoiceIds: string[];

  // The transactions that were created on the ledger for this
  transactionIds: string[];
}

export interface Payout {
  id: string;
  marketId: string;

  createdAt: Timestamp;
  updatedAt: Timestamp;
  updatedBy: string | null;
  customerId: string;
  // Credit only allowed for payouts created by the system
  method: PayoutMethod | "Credit";
  amountInCents: number;

  // for BACS these are present
  accountName?: string; // usually the customer's name
  accountNumber?: string;
  sortCode?: string;

  // for cheque these are present
  chequeMadePayableTo?: string;

  transactionDate: Timestamp;
  reference: string | null;
  status: "pending" | "complete" | "void";
  notes: string | null;

  completedAt?: Timestamp | null;
  completedBy?: string | null;

  voidedAt?: Timestamp | null;
  voidedBy?: string | null;
  voidReason?: string | null;

  // The transactions that were created on the ledger for this
  transactionIds: string[];

  // The invoices paid by this payout
  invoiceIds: string[];
}

export interface DraftInvoice extends InvoiceTotals {
  // the ID is deterministic so saving can be made idempotent
  id: string;

  currency: Currency;

  status: "draft";

  // the time the invoice was sent
  issuedAt?: Timestamp;

  // the identifiers for the lots the invoice is for
  lotIdents: { lotId: string; saleId: string }[];
  // buyer or seller
  clientType: ClientType;

  customerId: string;
  customerAccountNumber: string;

  // The name of the customer or trading name to go on the invoice
  name: string;
  // The customer's address that was used for this invoice.
  // The address influences how tax is calculated
  address: Address;
  email: string | null;

  // the super type of the invoice sheep or cattle etc
  superTypes?: SuperType[];

  // The settings around tax used to generate the invoice
  customerIsVATRegistered: boolean;
  customerWithinTaxRegion: boolean;

  // the line items that make up the invoice
  lineItems: InvoiceLineItem[];
  // adjustments are added as line items. Can also be used for levies etc
  adjustmentLineItems: InvoiceLineItem[];

  // the vat number of the client is required for the invoice if its going for export
  vatNumber?: string;

  // the IDs for any extra products that were added onto this invoice
  extraProductIds: string[];

  // if a seller, the preferred payout method for this invoice
  payoutMethod?: PayoutMethod | null;
  payoutParams?: {
    // default true, if any debts should be tackled first
    settleDebtsFirst: boolean;

    // For BACS
    accountName?: string;
    accountNumber?: string;
    sortCode?: string;

    // For cheque
    chequeMadePayableTo?: string;
  };

  // The exact payouts wont be accurate until the invoice is finalised
  potentialPayoutsById?: {
    [payoutId: string]: {
      id: string;
      amountInCents: number;
      paymentMethod: PaymentMethod;
      invoice?: {
        // included if this payout will pay off an invoice
        id: string;
        invoiceNumber: number;
        fullyPaid: boolean;
      };
    };
  };

  // We may need to collect some info for the lots at checkout (e.g. destination address)
  // atrributes here will get set onto the relevant lots once the invoice is created
  attributeValues?: {
    [attributekey: string]: AttributeValueType;
  };

  // The attributes to collect
  attributes: AttributeDefinition[];

  // customisable, shown on the invoice
  averages?: { label: string; value: number; formattedValue: string }[];

  paymentInstructions?: string | null;
}

export interface InvoiceTotals {
  //the lot total in cents
  lotTotalInCentsExVat: number;

  // the vat on the lot total in cents
  vatOnLotTotalInCents: number;

  // this calcluates the total commission from commissionLineItems exlusive of vat
  commissionTotalInCents?: number;
  // VAT on commission in cents. Needs to be seperate to allow for vat margin scheme
  vatOnCommissionInCents?: number;
  // the total adjustments from adjustmentLineItems exlusive of vat
  nonCommissionAdjustmentsInCents?: number;
  // VAT on adjustments in cents. Needs to be seperate to allow for vat margin scheme
  vatOnAdjustmentsInCents?: number;
  subtotalGroupTotals: {
    [key in SubtotalGroups]: number;
  };
  taxSubtotalGroupTotals: {
    [key in string]: number;
  };
  // the final total of the invoice after adjustments, commission and tax
  finalTotalInCents: number;
}

// Invoice
export interface SimplePaymentIn {
  paymentId: string;
  amountInCents: number;
  paymentMethod: PaymentMethod;
  reference: string | null;
  paidAt: Timestamp;
}

/**
 * Used on invoice to show the payout. Use payout collection for the source of truth
 */
export interface SimplePaymentOut {
  payoutId: string;
  amountInCents: number;
  paymentMethod: PaymentMethod;
  reference: string | null;
  paidAt: Timestamp;
}

export interface Invoice
  extends Omit<
    Omit<
      Omit<Omit<DraftInvoice, "ledgerAccountTotals">, "potentialPayoutsById">,
      "payoutParams"
    >,
    "status"
  > {
  id: string;
  invoiceNumber: number;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  updatedBy?: string | null;

  marketId: string;

  // a list of the sale IDs that this invoice was created for
  // needed for firebase querying
  saleIds: string[];
  sales: {
    // if this was created for a sale or group of sales then the sale id is included
    id: string;
    // the sale type. like EWES Sale, Store Sale etc,
    name?: string;
  }[];

  // The transactions that were created on the ledger for this invoice
  transactionIds?: string[];

  // issued- valid for seller (not cashed) and buyer ( not paid)
  status: "draft" | "issued" | "void" | "imported" | "paid";
  // TODO these aren't yet in place
  // | "uncollectible" | "void" | "archived";

  // A simplified copy of the Payment record that would have been created
  paymentsInById: {
    [paymentId: string]: SimplePaymentIn;
  };

  payoutMethod: PayoutMethod | null;
  payoutsById: {
    [payoutId: string]: SimplePaymentOut;
  };

  voidReason?: string;
  voidedAt?: Timestamp;
  voidedBy?: string;

  // a description of the invoice e.g. 'Invoice for 10 sheep'
  description?: string;

  // optional - if a haulier is involved in the sale then the haulier can be added here
  // their vehicle reg details and auth number will be pulled from their customer profile
  // haulierCustomerId?: string | null;

  // the footer text that appears on the invoice at the bottom. Can be used to add a note to the invoice like ppk or a note to the customer
  footnotes?: string[];

  metadata: {
    [key: string]: string | number | boolean | Timestamp | Media;
  };

  /***
   * this is important to add when adding an invoice
   * it creates a lock so we only ever get sequential invoice numbers
   * The invoice will have this as null until the next invoice is created
   */
  internalNextInvoiceId: string | null;

  // ID's of the email tasks that have been created for this invoice
  emailNotifications?: string[];

  pitchPayLink: string | null;
}
export interface Printer {
  name: string;
  id: string;
}

export interface InvoiceLineItem {
  id: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;

  // groups up on the subtotal lines on the invoice
  subtotalGroup?: SubtotalGroups | null;

  clientType: ClientType;

  description: string;

  // The sort order of the line item within the invoice
  index?: number;

  quantity: number;
  // The price of 1 item
  impreciseUnitPriceInCents: number;
  // helpful later for showing the price per unit
  unitOfSale?: UnitOfSale;
  unitPriceIncludesVat?: boolean;

  taxRate: TaxRate;
  taxAmountInCents: number;

  discountAmountInCents?: number;

  totalAmountInCentsExVat: number; // unitPriceInCents * quantity - discountAmountInCents

  // If this item relates to the sale of item(s)
  // handy for drilling down from an invoice to the specific items
  saleId?: string;
  lotId?: string;
  lotProductCode?: string;
  superType?: SuperType | null;
  // subtype?: ProductSubtype | null;
  // If this was a line item for an extra product
  productId?: string;
  // if this product applies to a lot then this is the lot ID
  productRelatedTo?: { lotId: string; saleId: string } | null;
  productObfusicate?: boolean;

  // The money for this line item goes to this account
  passthroughFundsToCustomerId?: string | null;

  // if this line was created from an adjustment
  adjustmentConfig?: AdjustmentsConfiguration;

  metadata: {
    [key: string]:
      | string
      | number
      | boolean
      | Timestamp
      | Media
      | { [key: string]: string | number | boolean | Timestamp };
  };
}

/// ---- The Ledger ----

export type TxnReferenceType =
  | "invoice"
  | "payment"
  | "payout"
  | "adjustment" // not an AdjustmentConfig but more of an edit to the leger
  | "reversal-invoice"
  | "reversal-payment"
  | "reversal-payout"
  | "opening-balance"
  | "rebalance"; // happens after an invoice is voided, so a trade payable / receivable isn't put +ve / -ve

// before it's written to the ledger
// DONT CREATE THIS MANUALLY - use the "createLedgerPartialTransaction" function
export type PartialTransaction = Omit<
  Omit<
    Omit<
      Omit<
        Omit<
          Omit<
            Omit<Transaction, "parentTransactionId">,
            "currentBalanceInCents"
          >,
          "index"
        >,
        "batchId"
      >,
      "batchDescription"
    >,
    "sumOfCreditsInCents"
  >,
  "sumOfDebitsInCents"
>;

export type TransactionTagsType = {
  [tag: string]: string | undefined | null | number | boolean;
};

export interface PendingTransactionBatch {
  id: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  transactionDate: Timestamp;
  batchDescription: string;
  topLevelTags: TransactionTagsType;
  transactions: PartialTransaction[];
}

export interface SimpleTransaction {
  account: string;
  amountInCents: number;
  tags: TransactionTagsType;
}

// A transaction on the ledger
// Accounts are almost emergent from the transactions.
// account names have a prefix for the account type
// transactions are immutable
export interface Transaction {
  id: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;

  marketId: string;

  // e.g. market:income:sales or asig1:expenses:purchases
  account: string;

  // e.g. ["market", "market:income"] or ["asig1", "asig1:expenses"]
  rollUpAccounts: string[];

  accountType: "income" | "expense" | "asset" | "liability" | "equity";

  // We hold accounts on the ledger for both the market and customers
  // "owner" is either the string "market" or a customer ID
  owner: "market" | string;

  // a transaction is either on the market account or a customer account
  isMarketAccount: boolean;

  // The amount of the transaction in cents
  amountInCents: number;

  // Many transactions are created at the same time to keep the ledger balanced.
  // This id groups all those transactions that were written together.
  batchId: string;

  // a friendly, user-facing description of the transaction
  batchDescription: string;

  // Transactions are created from invoices, payments or payouts.
  referenceId: string;
  referenceType: TxnReferenceType;

  tags: TransactionTagsType;

  // transactions are linked to each other in a chain
  // only null if it is the first transaction in the chain
  parentTransactionId: string | null;

  // The balance of the account after this transaction
  currentBalanceInCents: number;

  // The total of all the credits in the chain after this transaction
  sumOfCreditsInCents: number;

  // The sum total of all the debits in the chain after this transaction
  sumOfDebitsInCents: number;

  // the index of the transaction in the chain, also the count.
  index: number;

  // this is important to add when appending a transaction in the account
  // as locking with a firestore transaction over the parent and child transactions
  // ensures we dont get two children for the same parent and total balance is correct
  childTransactionId?: string;
}

export interface MaterialisedLedgerAccount {
  id: string; // this is the account slug e.g. "Revenue:GBP:Sales"

  marketId: string;

  type: string;
  accountName: string;
  currency: string;

  // if the account is related to a customer
  customerId?: string | null;

  // Always check the ledger directly to get the exact balance when
  // performing a transaction
  approximateBalanceInCents: number;

  latestTransactionDate: Timestamp;
  latestTransactionId: string;
  latestTransactionIndex: number;

  updatedAt: Timestamp;
}

// table positions for the tables in invoice PDFs
export interface TablePosition {
  id: InvoiceField | ChequeField | MarketReportHeaders;
  title: string;
  cellWidth: number; // width of each cell. Each cell is cummulatively added to the x position to build table
  halign: "left" | "center" | "right";
}

export type WebhookEventName =
  | "market.updated"
  | "sale.created"
  | "sale.updated"
  | "sale.deleted"
  | "lot.created"
  | "lot.updated"
  | "lot.deleted"
  | "invoice.created"
  | "invoice.updated"
  | "invoice.deleted"
  | "payment.created"
  | "payment.updated"
  | "payment.deleted"
  // customers
  | "customer.created"
  | "customer.updated"
  | "customer.deleted"
  // staff members
  | "member.created"
  | "member.updated"
  | "member.deleted"
  | "integration.installed"
  | "integration.uninstalled";

export interface WebhookConfig {
  id: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;

  marketId: string;

  // The event that triggers the webhook
  triggers: WebhookEventName[];

  // The URL to send the webhook to
  url: string;

  // The secret to sign the webhook with
  secret: string;

  // if true an API key will be sent in the header of the webhook that
  // expires after 10 mins
  sendTemporaryKey?: boolean;
}

export type ObjectType =
  | "market"
  | "sale"
  | "lot"
  | "invoice"
  | "payment"
  | "customer"
  | "member";

// The object that is sent to the recevier
export interface WebhookEvent<T> {
  id: string;
  createdAt: Timestamp;
  trigger: WebhookEventName;
  // the market the event relates to
  marketId: string;
  objectType: ObjectType;
  // note object ID not included as sub objects (like lots) need the sale ID too to be referenced. Better to get from the object itself
  // objectId: string
  data: {
    // the object the event relates to
    object: T;
    // the before / after change on the object
    objectBefore?: T;
  };
}

// use createStudioApiKey() to create this
export interface ApiKeyConfig {
  key: string;

  createdAt: Timestamp;
  // user ID of the user who created the key
  // Can also be "system" if the key was created by the system
  // system keys we use for communicating between
  // cloud functions and the api on vercel (to create pdf documents)
  createdBy: string;
  // The time the key was last used rounded to the hour
  lastUsedHour?: Timestamp;

  // The market the key grants access to
  marketId: string;

  // if present revokes the key
  revokedAt?: Timestamp;

  // If present the key will expire. (It wont add a revokedAt timestamp).
  expiresAt?: Timestamp;

  // Will automatically delete the key after this time
  deleteAfter?: Timestamp;
}

export interface EmailTask {
  id: string;
  marketId: string;
  customerId: string;
  templateId: string;

  // the emails to send the email to
  to: string[];

  // the urls of invoice documents to attach
  // need a way that we can securely get the invoice documents
  // just use an API key we generate for internal use. Create one if it doesn't exist
  documents?: string[];

  // data to pass into the template
  // any firestore document references will be fetched and passed in
  data: {
    [key: string]:
      | string
      | number
      | boolean
      | Timestamp
      | DocumentReference
      | DocumentReference[];
  };

  status:
    | "failed" // failed to send on our end
    | "pending"
    | "sent"
    | "delivered"
    | "opened"
    | "bounced"
    | "spam-complaint";
  errorCode?: number;
  errorMessage?: string;

  // the postmark message id
  providerMessageId?: string;

  createdAt: Timestamp;
  updatedAt: Timestamp;
  // the time we sent the email to postmark
  sentAt?: Timestamp;
  // the time postmark said the email was delivered to the origin server
  deliveredAt?: Timestamp;
  bouncedAt?: Timestamp;

  // Added by the email sending function
  subject?: string;

  events?: {
    id: string;
    timestamp: Timestamp;
    type: string;
    recipient: string;
  }[];
}

export type SupportedAttributeTypes =
  | "string"
  | "number"
  | "boolean"
  | "date"
  | "datetime"
  | "time";

export interface AttributeDefinition {
  // conventions
  //  prefix with @ for item level attributes
  id: string;
  name: string;
  shortName?: string;
  description?: string;

  // An array of {countryCode}/{superType} that this attribute applies to
  // e.g. "GB-NIR/Sheep" or "IE/Cattle"
  applyTo: string[];

  // lot => set all items to the same value in the lot
  // item => the items can be different values
  level: "item" | "lot";

  // default false
  readonly?: boolean;

  // default false. If the attribute can be displayed in the MartEye app / other catalogues
  // public?: boolean;

  // if the group is set the attribute will be grouped with other attributes in the same group
  lotDetailFlyoutGroup?: string;

  // If the attribute should be set at the checkout phase we assign it to a group
  // to appear on the page
  buyerCheckoutGroup?: string;
  sellerCheckoutGroup?: string;

  // if true, the values for this attribute will be copied over the customer to be used as
  // defaults for their next sale. (think CPH or Flock number)
  buyerCustomerAttribute?: boolean;
  sellerCustomerAttribute?: boolean;

  // If true will hide on the column on the sale sheet
  defaultHiddenOnSaleSheet?: boolean;

  // Only if the seller and product code are the same.
  // Effective only on the QuickSheet.
  prefillableFromPreviousLot?: boolean;

  // If true, isn't shown at all in the lot flyout or the sale sheet.
  // Handy for system level attributes.
  hidden?: boolean;

  // If true, the attribute will be shown in the seller details panel
  // in the quicksheet and in the Rostrum
  showInSellerDetailsPanel?: boolean;

  // --- Templates

  // A template used to render the value in the sale sheet (and other places)
  displayTemplate?: string;
  // for when the level is item, there's more than one value and it's being viewed in a lot context
  displayTemplateMultiValue?: string;

  // --- /Templates

  // --- Validation

  type: SupportedAttributeTypes;

  // true if this is required before the price is set.
  requiredPreSale: boolean;

  // true if can't checkout without this attribute being valid
  // but not needed before the price is set
  requiredToCheckout: boolean;

  // validation for number attributes
  minValue?: number;
  maxValue?: number;

  // validation for string attributes
  minLength?: number;
  maxLength?: number;
  // a regex pattern to match against
  regexPattern?: string;

  // validation for date attributes
  // we use node-chrono for parsing these
  // all expressions are parsed against the sale start date
  // examples:
  //      midnight today:     "2021-01-05T00:00:00.000Z"
  //      midnight:           "2021-01-05T00:00:00.000Z"
  //      noon:               "2021-01-05T12:00:00.000Z"
  //      midnight tomorrow:  "2021-01-06T00:00:00.000Z"
  //      yesterday:          "2021-01-04T01:00:00.000Z"
  //      next year:          "2022-01-05T01:00:00.000Z"
  //      5 days from now:    "2021-01-10T01:00:00.000Z"
  //      5 days ago:         "2020-12-31T01:00:00.000Z"
  //      60 days ago:        "2020-11-06T01:00:00.000Z"
  //      2 days from now:    "2021-01-07T01:00:00.000Z"
  //      2021-01-01T00:00:   "2021-01-01T00:00:00.000Z"
  minDateExpression?: string;
  maxDateExpression?: string;

  // if provided the attribute will only be allowed to be one of these values
  allowedValues?: string[];

  // ---/Validation
}

// Apps Intergations
export interface StudioAppPartial {
  id: string;
  name: string;
  version: string;
  description: string;
  icon: string;
  parameterConfig: AppParameterConfig[];
  parameterValues: {
    [key: string]: string | number | boolean | string[];
  };
}

export type AppParameterConfig = {
  id: string;
  type: "string" | "number" | "boolean" | "string[]" | "password";
  label: string;
  description?: string;
  required?: boolean;
  defaultValue?: string | number | boolean | string[];
};
