import { DocumentSpec, InvoicePDFProps } from "../../_importDocs";

// JS PDF
import { jsPDF } from "jspdf";
import { Market, SuperType } from "types";

import { getMarketBranding } from "../utils";

// Parts
import header from "../sheep-tag-list/parts/header";
import footer from "./parts/footer";
import table from "./parts/table";

const TagListSpec: DocumentSpec = {
  // must be unique
  id: "invoice",
  objectType: "invoice",

  // displayed to the user
  name: "Invoice",

  // when the PDF is downloaded, this function is called to generate the filename
  getFilename: (props) => {
    let { invoice, marketId } = props;
    let invoiceNumber =
      invoice.status === "draft" ? "Draft" : invoice.invoiceNumber;
    let filename = `invoice-${marketId}-${invoiceNumber}`;
    filename = filename.replace(/ /g, "-").replace(/[^a-zA-Z0-9-]/g, "");
    return `${filename}`;
  },

  // return true if this document is available for a market to generate
  isAvailableFor: (market: Market, superTypes: SuperType[]) => {
    return true;
  },
  // the document template
  jsPDF: (props: InvoicePDFProps) => {
    const { market, invoice } = props;
    const isDraft = props.isDraft ?? false;
    const isVoid = props.invoice.status === "void";

    let title = invoice.clientType === "Seller" ? "Statement" : "Invoice";
    if (isDraft) {
      title = `Draft ${title}`;
    }

    if (isVoid) {
      title = `Void ${title}`;
    }
    const margin = 10;
    const doc = new jsPDF({
      putOnlyUsedFonts: true,
      compress: true,
      unit: "mm",
      format: "a4",
    });

    const {
      dateFormat,
      textFillColor,
      legalText,
      tableHeaderFillColor,
      tableHeaderTextColor,
      tableStripeFillColor,
      tableStripeTextColor,
    } = getMarketBranding(market);

    header(doc, invoice, {
      output: false,
      dateFormat: dateFormat,
      title: title,
      margin: margin,
      textFillColor: textFillColor,
      logo: props.marketLogo,
      market: market,
    });

    footer(doc, invoice, {
      output: false,
      margin,
      textFillColor: textFillColor,
      legalText,
    });

    table(doc, invoice, {
      margin: margin,
      market,
      paymentLink: props.paymentLink,
      paymentQrCode: props.paymentQrCode,
      headerFillColor: tableHeaderFillColor,
      headerTextColor: tableHeaderTextColor,
      stripeFillColor: tableStripeFillColor,
      stripeTextColor: tableStripeTextColor,
    });

    header(doc, invoice, {
      output: true,
      dateFormat: dateFormat,
      title: title,
      margin: margin,
      textFillColor: textFillColor,
      logo: props.marketLogo,
      market: market,
    });

    footer(doc, invoice, {
      output: true,
      margin,
      textFillColor: textFillColor,
      legalText,
    });

    return doc;
  },
};

export default TagListSpec;
