import { formatAsCurrency, formatAsCurrencySubunit } from "@/data/amounts";
import { calculateAverages } from "@/data/averages";
import { classNames } from "@/data/classnames";
import {
  useFirestore,
  useLoadFirestoreDoc,
} from "@/data/studio-react/firebase/useFirestore";
import { format, isToday } from "date-fns";
import { doc } from "firebase/firestore";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import pluralize from "pluralize";

import React, { ReactNode, useEffect, useMemo, useState } from "react";

import { useCurrentUid } from "@/data/studio-react/firebase/useFirestoreAuth";
import { Cart, ClientType, Customer, Lot, Sale } from "../../../types";
import { useAllUninvoicedLotsBoughtAndSold } from "../useAllLotsBroughtAndSold";

import { Menu, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import { usePopper } from "react-popper";
import { Portal } from "react-portal";

// Icons
import {
  BanknotesIcon,
  ChevronDownIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import EmptyCartIcon from "../../../public/images/empty-cart.svg";

import { LotStatusButton } from "@/components/Buttons";
import AddCartItemDialog from "@/components/invoice/AddCartProduct";

import { TrashIcon } from "@heroicons/react/20/solid";
import { useShowDialog } from "../../ConfirmationDialogProvider";
import { useOpenLotFlyout } from "../../LotFlyout/LotFlyout";
import { useDeleteCartItem } from "../../invoice/useDeleteCartItem";

// Components
import TransferLotDialog from "@/components/PersonFlyout/Tabs/Cart/TransferLot";
import { useCheckoutSheet } from "@/components/checkout-sheet-draft/useCheckoutSheet";
import { getDisplayName } from "@/data/customerUtils";
import useStudio from "@/data/studio-react/useStudio";
import { useStudioStream } from "@/data/studio-react/useStudioStream";
import { getFunctions, httpsCallable } from "firebase/functions";

interface CartTabProps {
  customer: Customer | null;
  marketId: string;
  setIsTabLoading?: (isLoading: boolean) => void;
}

export function CartTab(props: CartTabProps) {
  let { customer, marketId, setIsTabLoading } = props;
  let customerId = customer?.id || null;

  let currentUid = useCurrentUid();

  let router = useRouter();
  let saleId = router.query.saleId as string | undefined;

  useWarmUpCreateDraftInvoice(customer, marketId);

  let lotInfo = useStudioStream("sale:lots-info");
  let { lotsBroughtThisSale, lotsSoldThisSale } = useMemo(() => {
    let lotsBroughtThisSale = [] as Lot[];
    let lotsSoldThisSale = [] as Lot[];

    if (!customerId) {
      return { lotsBroughtThisSale, lotsSoldThisSale };
    }

    for (let l of lotInfo.data) {
      if (
        l.buyerCustomerId === customerId &&
        !["Rerun", "Resold"].includes(l.saleStatus ?? "")
      ) {
        if (!l.buyerInvoiceId) {
          lotsBroughtThisSale.push(l);
        }
      }
      if (
        l.sellerCustomerId === customerId &&
        !["Rerun", "Resold"].includes(l.saleStatus ?? "")
      ) {
        if (!l.sellerInvoiceId) {
          lotsSoldThisSale.push(l);
        }
      }
    }

    return { lotsBroughtThisSale, lotsSoldThisSale };
  }, [lotInfo.data, customerId]);

  useEffect(() => {
    if (setIsTabLoading) {
      setIsTabLoading(lotInfo.loading);
    }
  }, [lotInfo.loading]);

  let { boughtLots, soldLots, isLoading } = useAllUninvoicedLotsBoughtAndSold(
    marketId,
    customerId,
    // excludes this sale from the list of lots brought and sold
    saleId
  );

  let { lotsBroughtBySale, lotsSoldBySale, key } = useMemo(() => {
    // group the lots by sale. Show purchases and sales for each sale

    let key = "";

    let lotsBroughtBySale = [] as {
      saleId: string;
      marketId: string;
      lots: Lot[];
    }[];

    let lotsSoldBySale = [] as {
      saleId: string;
      marketId: string;
      lots: Lot[];
    }[];

    if (!customerId) {
      return {
        lotsBroughtBySale,
        lotsSoldBySale,
        key,
      };
    }

    for (let l of lotsBroughtThisSale) {
      key += l.id;

      let sale = lotsBroughtBySale.find(
        (s) => s.saleId === l.saleId && s.marketId === l.marketId
      );
      if (!sale) {
        lotsBroughtBySale.push({
          saleId: l.saleId,
          marketId: l.marketId,
          lots: [l],
        });
      } else {
        sale.lots.push(l);
      }
    }

    for (let l of lotsSoldThisSale) {
      key += l.id;

      let sale = lotsSoldBySale.find(
        (s) => s.saleId === l.saleId && s.marketId === l.marketId
      );
      if (!sale) {
        lotsSoldBySale.push({
          saleId: l.saleId,
          marketId: l.marketId,
          lots: [l],
        });
      } else {
        sale.lots.push(l);
      }
    }

    for (let l of boughtLots) {
      key += l.id;

      let sale = lotsBroughtBySale.find(
        (s) => s.saleId === l.saleId && s.marketId === l.marketId
      );
      if (!sale) {
        lotsBroughtBySale.push({
          saleId: l.saleId,
          marketId: l.marketId,
          lots: [l],
        });
      } else {
        sale.lots.push(l);
      }
    }

    for (let l of soldLots) {
      key += l.id;

      let sale = lotsSoldBySale.find(
        (s) => s.saleId === l.saleId && s.marketId === l.marketId
      );
      if (!sale) {
        lotsSoldBySale.push({
          saleId: l.saleId,
          marketId: l.marketId,
          lots: [l],
        });
      } else {
        sale.lots.push(l);
      }
    }

    return {
      lotsBroughtBySale,
      lotsSoldBySale,
      key,
    };
  }, [customerId, lotsBroughtThisSale, lotsSoldThisSale, boughtLots, soldLots]);

  let { openCheckoutSheet } = useCheckoutSheet();

  const [addCartDialog, setAddCartDialog] = useState(false);
  const [cartType, setCartType] = useState<ClientType>("Buyer");

  // Cart Extras
  let firestore = useFirestore();
  let docQuery = useMemo(() => {
    return doc(firestore, `markets/${marketId}/carts/${customerId}`);
  }, [firestore, marketId, customerId]);
  let cartLoad = useLoadFirestoreDoc<Cart>(docQuery);
  let extras = cartLoad.data;

  let defaults = useStudioStream("marketDefaultSettings", marketId);
  let currency = defaults?.defaultCurrency;

  if (!currentUid) {
    return null;
  }

  if (isLoading === false) {
    if (lotsBroughtBySale.length === 0 && lotsSoldBySale.length === 0) {
      return (
        <div className="flex flex-col justify-center items-center h-full pb-16">
          <Image
            src={EmptyCartIcon}
            width={300}
            height={300}
            alt=""
            className="mb-4"
            priority={true}
          />

          <h2 className="font-bold text-martEye-600 text-2xl">
            This cart is currently empty
          </h2>
          <p className="text-martEye-600 font-bold">
            This customer has no lots to check out
          </p>
        </div>
      );
    }
  }

  return (
    <div
      key={key}
      className="flex-grow flex-col overflow-scroll pr-3 pl-3 pt-3"
    >
      <LotsCheckoutTableView
        title="Purchases"
        lotsBySale={lotsBroughtBySale}
        extras={extras}
        type="Buyer"
        customerId={customerId}
        actions={(selectedLots: Lot[]) => {
          return [
            <ActionButton
              key={"add"}
              title="Add"
              icon={<PlusIcon className="-mr-0.5 h-5 w-5" />}
              iconAfter={true}
              onClick={() => {
                setCartType("Buyer");
                setAddCartDialog(true);
              }}
              isSecondary={true}
              selectedLots={selectedLots}
            />,
            // <ActionButton
            //   title="Draft Invoice"
            //   icon={<ArrowDownTrayIcon className="-mr-0.5 h-5 w-5" />}
            //   iconAfter={true}
            //   onClick={(selectedLots) => {
            //     if (selectedLots.length === 0) {
            //       return;
            //     }

            //     const lots = selectedLots
            //       .map((l) => `${l.saleId}-${l.id}`)
            //       .join(",");

            //     window.open(
            //       `/api/${marketId}/documents/invoice/draft?lots=${lots}&clientType=Buyer`,
            //       "_blank"
            //     );
            //   }}
            //   isSecondary={true}
            // />,

            <ActionDropdown
              key="action-dropdown"
              customer={customer}
              clientType="Buyer"
              marketId={marketId}
              currentUid={currentUid}
              selectedLots={selectedLots}
            />,

            <ActionButton
              key="create"
              title="Create"
              icon={<BanknotesIcon className="-mr-0.5 h-5 w-5" />}
              iconAfter={true}
              selectedLots={selectedLots}
              disabled={selectedLots.length === 0}
              onClick={(selectedLots) => {
                if (selectedLots.length === 0) {
                  return;
                }
                openCheckoutSheet({
                  lots: selectedLots,
                  clientType: "Buyer",
                });
              }}
            />,
          ];
        }}
      />
      <LotsCheckoutTableView
        title="Sales"
        lotsBySale={lotsSoldBySale}
        actions={(selectedLots) => {
          return [
            <ActionButton
              key={"add"}
              title="Add"
              icon={<PlusIcon className="-mr-0.5 h-5 w-5" />}
              iconAfter={true}
              onClick={() => {
                setCartType("Seller");
                setAddCartDialog(true);
              }}
              isSecondary={true}
              selectedLots={selectedLots}
            />,
            // <ActionButton
            //   title="Draft Statement"
            //   icon={<ArrowDownTrayIcon className="-mr-0.5 h-5 w-5" />}
            //   iconAfter={true}
            //   onClick={(selectedLots) => {
            //     if (selectedLots.length === 0) {
            //       return;
            //     }

            //     const lots = selectedLots
            //       .map((l) => `${l.saleId}-${l.id}`)
            //       .join(",");

            //     window.open(
            //       `/api/${marketId}/documents/invoice/draft?lots=${lots}&clientType=Seller`,
            //       "_blank"
            //     );
            //   }}
            //   isSecondary={true}
            // />,

            <ActionDropdown
              key="action-dropdown"
              customer={customer}
              clientType="Seller"
              marketId={marketId}
              currentUid={currentUid}
              selectedLots={selectedLots}
            />,

            <ActionButton
              key="create"
              title="Create"
              icon={<BanknotesIcon className="-mr-0.5 h-5 w-5" />}
              iconAfter={true}
              onClick={(selectedLots) => {
                if (selectedLots.length === 0) {
                  return;
                }
                openCheckoutSheet({
                  lots: selectedLots,
                  clientType: "Seller",
                });
              }}
              disabled={selectedLots.length === 0}
              selectedLots={selectedLots}
            />,
          ];
        }}
        extras={extras}
        customerId={customerId}
        type="Seller"
      />

      <AddCartItemDialog
        currency={currency ?? "GBP"}
        open={addCartDialog}
        setOpen={setAddCartDialog}
        cartType={cartType}
        marketId={marketId}
        customerId={customerId}
        currentUid={currentUid}
      />
    </div>
  );
}

function ActionDropdown(props: {
  selectedLots?: Lot[];
  customer: Customer | null;
  clientType: ClientType;
  marketId: string;
  currentUid: string | null;
}) {
  const { selectedLots, customer, clientType, marketId, currentUid } = props;

  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState<HTMLButtonElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 0],
        },
      },
    ],
  });

  let { showDialog } = useShowDialog();

  const [showTransferDialog, setShowTransferDialog] = useState(false);

  let { updateLots } = useStudio();

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            ref={setTargetElement}
            className="inline-flex items-center gap-x-1.5 rounded-md border-2 border-gray-200 bg-white px-3 py-2 text-sm font-medium leading-4  text-martEye-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-martEye-400 focus:ring-offset-2 hover:border-martEye-500 hover:bg-martEye-500 hover:text-white"
          >
            Actions
            <ChevronDownIcon
              className="-mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Portal>
          <div
            ref={popperElRef}
            style={styles.popper}
            {...attributes.popper}
            className="z-50"
          >
            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              as={Fragment}
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item disabled={selectedLots?.length === 0}>
                    {({ active, disabled }) => (
                      <Link
                        href={`/api/${marketId}/documents/${
                          clientType === "Buyer" ? "buyers" : "sellers"
                        }-cart-summary/${customer?.id}`}
                        target="_blank"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm",
                          disabled ? "cursor-not-allowed" : "cursor-pointer"
                        )}
                      >
                        {clientType === "Buyer"
                          ? "Purchases Summary"
                          : "Sales Summary"}
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item disabled={selectedLots?.length === 0}>
                    {({ active, disabled }) => (
                      <button
                        onClick={() => {
                          setShowTransferDialog(true);
                        }}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm",
                          disabled ? "cursor-not-allowed" : "cursor-pointer"
                        )}
                      >
                        Transfer
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item disabled={selectedLots?.length === 0}>
                    {({ active, disabled }) => (
                      <button
                        onClick={() => {
                          const lotNumbers = selectedLots?.map(
                            (item) => item.lotNumber
                          );

                          showDialog(
                            `Remove` +
                              pluralize(" Lot", selectedLots?.length ?? 0),
                            `This will remove the ${pluralize(
                              " Lot",
                              selectedLots?.length ?? 0
                            )}: ${lotNumbers?.join(", ")} from ${getDisplayName(
                              customer
                            )}
                          \n Are you sure?`,
                            "Remove",
                            () => {
                              if (clientType === "Buyer") {
                                if (selectedLots) {
                                  updateLots(
                                    selectedLots.map((l) => l.id),
                                    {
                                      buyerCustomerId: null,
                                    }
                                  );
                                }
                              } else if (clientType === "Seller") {
                                if (selectedLots) {
                                  updateLots(
                                    selectedLots.map((l) => l.id),
                                    {
                                      sellerCustomerId: null,
                                    }
                                  );
                                }
                              }
                            },
                            "Cancel",
                            () => {},
                            "danger"
                          );
                        }}
                        className={classNames(
                          active
                            ? "bg-danger-400 text-white"
                            : "text-danger-500",
                          "block w-full px-4 py-2 text-left text-sm",
                          disabled ? "cursor-not-allowed" : "cursor-pointer"
                        )}
                      >
                        Delete
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </div>
        </Portal>
      </Menu>

      <TransferLotDialog
        open={showTransferDialog}
        setOpen={setShowTransferDialog}
        clientType={clientType}
        lots={selectedLots ?? []}
        currentUid={currentUid}
      />
    </>
  );
}

export function ActionButton(props: {
  title: string;
  icon?: React.ReactNode;
  iconAfter?: boolean;
  disabled?: boolean;
  onClick: (lots: Lot[]) => void;
  selectedLots?: Lot[];
  isSecondary?: boolean;
}) {
  let { title, onClick, selectedLots, isSecondary, icon, iconAfter, disabled } =
    props;

  return (
    <button
      type="button"
      className={classNames(
        "inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-medium leading-4 shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2",
        !isSecondary
          ? "border-2 border-martEye-400 bg-martEye-400 text-white"
          : "border-2 border-gray-200 bg-white text-martEye-700",
        !isSecondary
          ? "hover:border-martEye-500 hover:bg-martEye-500"
          : "hover:border-martEye-500 hover:bg-martEye-500 hover:text-white",
        !isSecondary ? "focus:ring-martEye-400" : "focus:ring-martEye-400",
        disabled
          ? "cursor-not-allowed opacity-50"
          : "cursor-pointer focus:ring-offset-white"
      )}
      disabled={disabled}
      onClick={() => {
        onClick(selectedLots || []);
      }}
    >
      {!iconAfter && icon}
      {title}
      {iconAfter && icon}
    </button>
  );
}

interface LotsToCheckoutTableViewProps {
  title: string;
  type: ClientType;
  lotsBySale: {
    saleId: string;
    marketId: string;
    lots: Lot[];
  }[];

  actions: (selectedLots: Lot[]) => ReactNode[];
  extras: Cart | null;
  customerId: string | null;
}

function LotsCheckoutTableView(props: LotsToCheckoutTableViewProps) {
  let { lotsBySale, extras, customerId, type } = props;

  // let [selected, setSelected] = useState<Lot[]>([]);

  let [selected, setSelected] = useState(() => {
    let s = [] as Lot[];
    for (let sale of lotsBySale) {
      for (let l of sale.lots) {
        if (l.generated?.totalValueInCents !== undefined) {
          s.push(l);
        }
      }
    }
    return s;
  });

  let allLots = useMemo(() => {
    return lotsBySale.flatMap((s) => s.lots);
  }, [lotsBySale]);

  let allSelectableLots = allLots
    .filter((l) => l.generated?.totalValueInCents !== undefined)
    .filter((l) =>
      Object.values(l.issues || {}).some((i) => i.blockCheckout === true)
    );

  if (lotsBySale.every((s) => s.lots.length === 0)) {
    return null;
  }

  // pass the selected lots to the action button

  let actions = useMemo(() => {
    return props.actions(selected);
  }, [selected, props.actions]);

  let marketId = useStudioStream("session:marketId");
  let products = useStudioStream("products", marketId);
  let deleteCartItem = useDeleteCartItem();
  let { showDialog } = useShowDialog();
  let marketDefaults = useStudioStream("marketDefaultSettings", marketId);

  // Filter out lots that are not in the cart
  let cartExtras = extras?.itemsById ?? [];
  let cartItems = Object.values(cartExtras).filter(
    (extra) => extra.clientType === type
  );

  let averages = calculateAverages(
    marketDefaults?.defaultCurrency ?? "GBP",
    selected
  );

  // must have only 4 averages
  if (averages.length > 4) {
    averages = averages.slice(0, 5);
  }
  if (averages.length < 4) {
    // add empty averages
    let emptyAverages = Array.from({ length: 4 - averages.length }, () => ({
      label: "",
      value: 0,
      formattedValue: "",
    }));
    averages = averages.concat(emptyAverages);
  }

  let totalCostOfExtras = Object.values(extras?.itemsById || {}).reduce(
    (acc, extra) => {
      acc += extra.unitPriceInCents * extra.quantity;
      return acc;
    },
    0
  );

  let checkboxRef = useRef<HTMLInputElement>(null);
  let allLotsChecked =
    selected.length === allSelectableLots.length && selected.length > 0;
  let someLotsChecked = selected.length > 0;

  if (checkboxRef.current) {
    checkboxRef.current.indeterminate = someLotsChecked && !allLotsChecked;
    checkboxRef.current.checked = allLotsChecked;
  }

  return (
    <>
      <div className="px-1">
        <div className="mt-4 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <div className="flex items-center justify-between border-b border-b-gray-200 bg-white px-4 py-3">
                  <h2 className="text-xl font-bold">
                    {props.title} ({selected.length})
                  </h2>
                  <div className="flex gap-x-2">{actions}</div>
                </div>
                <div className="grid w-full grid-cols-6 gap-4 border-t border-b bg-gray-50 p-4 text-martEye-700">
                  <div className="flex flex-col col-span-1 ">
                    <span className="text-sm font-normal text-gray-500 text-left">
                      Total
                    </span>
                    <span
                      className="text-xl font-bold"
                      title="Total value of all selected lots"
                    >
                      {formatAsCurrency(
                        marketDefaults?.defaultCurrency ?? "GBP",
                        selected.reduce(
                          (acc, lot) =>
                            acc + (lot.generated?.totalValueInCents ?? 0),
                          0
                        )
                      )}
                    </span>
                  </div>
                  <div className="flex flex-col col-span-1 order-last border-l border-gray-200 px-4">
                    <span className="text-sm font-normal text-gray-500 text-left">
                      Quantity
                    </span>
                    <span
                      className="font-bold"
                      title="Total value of all selected lots"
                    >
                      {selected.reduce(
                        (acc, lot) => acc + (lot.generated?.countOfItems ?? 0),
                        0
                      )}
                    </span>
                  </div>

                  {averages.map((average, idx) => (
                    <div
                      key={`${idx}`}
                      className="flex flex-col col-span-1 order-last border-l border-gray-200 pr-2"
                    >
                      <span className="text-sm font-normal text-gray-500 text-right">
                        {average.label}
                      </span>
                      <span
                        className="font-bold text-right"
                        title="Total value of all selected lots"
                      >
                        {average.formattedValue}
                      </span>
                    </div>
                  ))}
                </div>
                <table className="min-w-full">
                  <thead className="bg-white">
                    <tr className="bg-gray-50">
                      <th scope="col" className="relative py-2.5 pl-2 pr-2">
                        <span className="sr-only">Select</span>
                        <input
                          ref={checkboxRef}
                          name="select-deseclect-all"
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              // Only can select lots that have a price
                              setSelected(allSelectableLots);
                            } else {
                              setSelected([]);
                            }
                          }}
                          className="absolute top-3 left-4 h-4 w-4 border-gray-300 text-green-600 focus:ring-green-500"
                        />
                      </th>
                      <th
                        scope="col"
                        className="py-2 pl-4 pr-3 text-left text-sm font-medium text-gray-500"
                      >
                        Lot
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-2 text-center text-sm font-normal text-gray-500"
                        colSpan={3}
                      >
                        Details
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 text-sm font-medium text-gray-500 text-center"
                      >
                        Qty
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 text-left text-sm font-medium text-gray-500"
                      >
                        Price
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 text-right text-sm font-medium text-gray-500"
                      >
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {lotsBySale
                      .filter((s) => s.lots.length > 0)
                      .map((s) => (
                        <SaleSection
                          key={`${s.marketId}-${s.saleId}`}
                          marketId={s.marketId}
                          saleId={s.saleId}
                          selectableLotIds={allSelectableLots.map((l) => l.id)}
                          lots={s.lots}
                          selected={selected}
                          setSelected={setSelected}
                        />
                      ))}
                  </tbody>
                </table>

                {cartItems.length > 0 && (
                  <div>
                    <div className="w-full grid-cols-2 gap-4 border-t bg-gray-50 py-2 px-4">
                      <h3 className="text-sm font-bold text-martEye-700/75">
                        Extras
                      </h3>
                    </div>
                    <div className="grid w-full grid-cols-2 gap-4 border-t bg-white py-2 px-4">
                      <div></div>
                      <div className="divide divide-y-2">
                        {cartItems.map((extra) => {
                          let title =
                            products.find((p) => p.id === extra.productId)
                              ?.name || extra.productId;

                          let formattedTotal = formatAsCurrency(
                            marketDefaults?.defaultCurrency ?? "GBP",
                            extra.unitPriceInCents * extra.quantity
                          );

                          return (
                            <div className="flex items-center justify-between py-2">
                              <dt className="inline-flex gap-1.5 text-sm font-medium items-center">
                                {title}
                                <CustomerCartLookup
                                  marketId={marketId}
                                  customerId={
                                    extra.passthroughFundsToCustomerId ?? null
                                  }
                                />
                              </dt>
                              <dd className="text-sm font-medium">
                                {formattedTotal}{" "}
                                <button
                                  onClick={() => {
                                    showDialog(
                                      "Delete Extra",
                                      "This will delete the extra. Are you sure?",
                                      "Delete",
                                      () => {
                                        if (customerId) {
                                          deleteCartItem(customerId, extra.id);
                                        }
                                      },
                                      "Cancel",
                                      () => {},
                                      "danger"
                                    );
                                  }}
                                  className="rounded-full bg-gray-400 p-1 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-danger-400 hover:bg-danger-400"
                                >
                                  <span className="sr-only">
                                    Delete {title}
                                  </span>
                                  <TrashIcon
                                    className="h-3 w-3"
                                    aria-hidden="true"
                                  />
                                </button>
                              </dd>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function SaleSection(props: {
  marketId: string;
  saleId: string;
  lots: Lot[];
  selectableLotIds: string[];

  selected: Lot[];
  setSelected: (lots: Lot[]) => void;
}) {
  let saleInContext = useStudioStream("sale:sale");

  if (saleInContext?.id === props.saleId) {
    return (
      <SaleSectionInner
        sale={saleInContext}
        lots={props.lots}
        selectableLotIds={props.selectableLotIds}
        selected={props.selected}
        setSelected={props.setSelected}
      />
    );
  }

  return (
    <SaleSectionLoadIn
      marketId={props.marketId}
      saleId={props.saleId}
      selectableLotIds={props.selectableLotIds}
      lots={props.lots}
      selected={props.selected}
      setSelected={props.setSelected}
    />
  );
}

function SaleSectionLoadIn(props: {
  marketId: string;
  saleId: string;
  lots: Lot[];
  selectableLotIds: string[];
  selected: Lot[];
  setSelected: (lots: Lot[]) => void;
}) {
  let sale = useStudioStream("sale", props.marketId, props.saleId);

  return (
    <SaleSectionInner
      sale={sale}
      lots={props.lots}
      selected={props.selected}
      selectableLotIds={props.selectableLotIds}
      setSelected={props.setSelected}
    />
  );
}

function SaleSectionInner(props: {
  sale?: Sale | null;
  lots: Lot[];
  selected: Lot[];
  selectableLotIds: string[];
  setSelected: (lots: Lot[]) => void;
}) {
  let { lots, sale, selected, setSelected, selectableLotIds } = props;

  // tuesday 1st june
  let dateFormat = "EEE, do MMM";

  let date = sale ? sale.startsAt.toDate() : null;
  let today = date ? isToday(date) : false;

  let saleName = sale ? sale.name : "";
  let saleDate = date ? (today ? null : format(date, dateFormat)) : "";
  let { openLotFlyout } = useOpenLotFlyout();

  let totalItemsInSale =
    props.lots.reduce((acc, lot) => {
      return acc + (lot?.generated?.countOfItems ?? 0);
    }, 0) || 0;

  let lotsFromSale = lots.filter((l) => l.saleId === sale?.id);
  let selectableLotsFromSale = lotsFromSale.filter((l) =>
    selectableLotIds.includes(l.id)
  );
  let allLotsFromSaleSelected = selectableLotsFromSale.every((l) =>
    selected.map((s) => s.id).includes(l.id)
  );
  let someLotsFromSaleSelected = selectableLotsFromSale.some((l) =>
    selected.map((s) => s.id).includes(l.id)
  );

  let checkboxRef = useRef<HTMLInputElement>(null);

  if (checkboxRef.current) {
    checkboxRef.current.checked = allLotsFromSaleSelected;
    checkboxRef.current.indeterminate =
      someLotsFromSaleSelected && !allLotsFromSaleSelected;
  }

  return (
    <>
      <tr className="border-t border-gray-200">
        <th
          colSpan={2}
          className="bg-gray-50 py-1 pl-4 pr-4 text-left text-sm font-normal text-gray-500"
        >
          {/* Select all from this section */}
          <input
            ref={checkboxRef}
            name="select-all"
            type="checkbox"
            onChange={(e) => {
              if (e.target.checked) {
                let lots = Array.from(
                  new Set([...selected, ...selectableLotsFromSale])
                );
                setSelected(lots);
              } else {
                // Uncheck only the ones from this sale
                setSelected(selected.filter((s) => !lotsFromSale.includes(s)));
              }
            }}
            className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-500"
          />
        </th>
        <th
          colSpan={1}
          scope="colgroup"
          className="bg-gray-50 py-1 pl-4 pr-4 text-left text-sm font-normal text-gray-500 "
        >
          <span className="text-xs font-medium text-gray-500">{saleDate}</span>
        </th>

        <th
          colSpan={1}
          scope="colgroup"
          className="bg-gray-50 py-1 pl-4 pr-4 text-left text-sm font-normal text-gray-500 "
        >
          <span className="text-gray-600">{saleName}</span>
        </th>
        <th
          colSpan={4}
          scope="colgroup"
          className="bg-gray-50 py-1 pl-4 pr-4 text-left text-sm font-normal text-gray-500 "
        >
          <span className="text-gray-600/80 font-bold">{totalItemsInSale}</span>
        </th>
      </tr>

      {props.lots.map((l, idx) => {
        return (
          <tr
            key={`${sale?.id}${l.id}`}
            className={classNames(
              idx === 0 ? "border-gray-300" : "border-gray-200",
              "border-t"
            )}
          >
            {/* a checkbox */}
            <td className="relative py-2 pl-4 pr-4 text-sm font-medium whitespace-nowrap w-1">
              <input
                id={l.id}
                name="lot-select"
                type="checkbox"
                checked={selected.includes(l)}
                disabled={!props.selectableLotIds.includes(l.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelected([...selected, l]);
                  } else {
                    setSelected(selected.filter((s) => s.id !== l.id));
                  }
                }}
                className="h-4 w-4 border-gray-300 text-green-600 focus:ring-green-500 disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-300"
              />
            </td>

            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 w-1">
              <LotStatusButton
                lot={l.lotNumber ?? ""}
                onClick={() => openLotFlyout(l.id ?? null)}
              />
            </td>

            <td
              className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-light text-gray-900 w-11/12"
              colSpan={3}
            >
              {l.generated?.description}
            </td>

            {/* <td
              className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-light text-gray-900 "
              colSpan={3}
            >
              {createDetailsString(sale!, l).join(", ")}
            </td> */}

            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-light text-gray-900 w-1 text-center">
              {l.generated?.countOfItems}
            </td>

            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-light text-gray-900 ">
              {l.unitOfSale === "Per KG"
                ? formatAsCurrencySubunit(l.currency, l.unitPriceInCents)
                : formatAsCurrency(l.currency, l.unitPriceInCents)}
            </td>

            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-right text-sm font-normal text-gray-900">
              {formatAsCurrency(
                l.currency === "GUINEA-GBP" ? "GBP" : l.currency,
                l.generated?.totalValueInCents
              )}
            </td>
          </tr>
        );
      })}
    </>
  );
}

/***
 * Shoot a dud call to the function to warm it up
 */
function useWarmUpCreateDraftInvoice(
  customer: Customer | null,
  marketId: string
) {
  useEffect(() => {
    if (customer?.id) {
      invoke(marketId).then().catch();
    }
  }, [customer?.id, marketId]);
}

async function invoke(marketId: string) {
  let func = httpsCallable(
    getFunctions(undefined, "europe-west2"),
    "createDraftInvoiceCall"
  );
  try {
    await func({ marketId, warmup: true });
  } catch (e) {
    // silently fail. There's no response we want
  }
}

const CustomerCartLookup = ({
  customerId,
  marketId,
}: {
  customerId: string | null;
  marketId: string | null;
}) => {
  let customer = useStudioStream("customer", marketId, customerId);

  if (customer === null || marketId === null) {
    return null;
  }

  return (
    <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
      Going to: {customer.accountNumber}
    </span>
  );
};
