// importDocuments.ts

import {
  CartItem,
  ClientType,
  Customer,
  DraftInvoice,
  Invoice,
  Lot,
  Market,
  Sale,
  SuperType,
} from "../types";

import jsPDF from "jspdf";
import ChequeSpec from "./invoice-docs/cheque";
import FoodChainInformationSpecCattle from "./invoice-docs/foodChainInformationCattle";
import FoodChainInformationSpecSheep from "./invoice-docs/foodChainInformationSheep";
import InvoiceSpec from "./invoice-docs/invoice";
import MovementNotificationCattleSpec from "./invoice-docs/movementNotificationCattle";
import MovementNotificationSheepSpecGB from "./invoice-docs/movementNotificationSheepGB";
import MovementNotificationSheepSpecNI from "./invoice-docs/movementNotificationSheepNI";
import PassoutSpec from "./invoice-docs/passout";
import BuyersSummarySpec from "./sale-docs/buyersSummary";
import VendorSummarySpec from "./sale-docs/vendorSummary";

// V2
import TagListSpec from "./invoice-docs/tag-list";
import SheepTagListSpec from "./invoice-docs/sheep-tag-list";
import SaleTagListSpec from "./sale-docs/sale-tag-list";

// Cart Docs
import BuyersCartSummarySpec from "./cart-docs/buyersCartSummary";
import SellersCartSummarySpec from "./cart-docs/sellersCartSummary";
import SaleSummarySpec from "./sale-docs/saleSummary";

// Reports
import { Report } from "../documents/report-docs/config";
import { ReportData } from "../pages/api/[marketId]/documents/reports/[reportId]";
import AccountAuditTrail from "./report-docs/sales/accountAuditTrail";
import BuyerAuditTrail from "./report-docs/sales/buyerAuditTrail";
import BuyerDeductionsAuditTrail from "./report-docs/sales/buyerDeductionsAuditTrail";
import BuyerExtraDeductionsAuditTrail from "./report-docs/sales/buyerExtraDeductionsAuditTrail";
import ContraAuditTrail from "./report-docs/sales/contraAuditTrail";
import LivestockLotAuditTrail from "./report-docs/sales/livestockLotAuditTrail";
import MarketSummary from "./report-docs/sales/marketSummary";
import PaymentInAuditTrail from "./report-docs/sales/paymentInAuditTrail";
import PaymentOutAuditTrail from "./report-docs/sales/paymentOutAuditTrail";
import ProductCategoryAnalysis from "./report-docs/sales/productCategoryAnalysis";
import SellerAuditTrail from "./report-docs/sales/sellerAuditTrail";
import SellerDeductionsAuditTrail from "./report-docs/sales/sellerDeductionsAuditTrail";
import SellerExtraDeductionsAuditTrail from "./report-docs/sales/sellerExtraDeductionsAuditTrail";

import "./fonts/Inter-bold.js";
import "./fonts/Inter-extrabold.js";
import "./fonts/Inter-light.js";
import "./fonts/Inter-normal.js";

// ** Add new documents here **
const modules = [
  InvoiceSpec,
  TagListSpec,
  PassoutSpec,
  FoodChainInformationSpecSheep,
  FoodChainInformationSpecCattle,
  SaleSummarySpec,
  VendorSummarySpec,
  BuyersSummarySpec,
  ChequeSpec,
  MovementNotificationSheepSpecGB,
  MovementNotificationSheepSpecNI,
  MovementNotificationCattleSpec,
  SheepTagListSpec,
  SellersCartSummarySpec,
  BuyersCartSummarySpec,
  SellerAuditTrail,
  BuyerAuditTrail,
  LivestockLotAuditTrail,
  ProductCategoryAnalysis,
  MarketSummary,
  BuyerDeductionsAuditTrail,
  BuyerExtraDeductionsAuditTrail,
  SellerDeductionsAuditTrail,
  SellerExtraDeductionsAuditTrail,
  ContraAuditTrail,
  AccountAuditTrail,
  PaymentInAuditTrail,
  PaymentOutAuditTrail,
  SaleTagListSpec,
];
// ** ---------------------- **

export interface InvoicePDFProps {
  marketId: string;
  documentId: string;
  invoiceId: string;
  invoice: Invoice | DraftInvoice;
  market: Market;
  sales: Sale[];
  lots: Lot[];
  customer: Customer;
  isDraft?: boolean;

  paymentLink?: string | null;
  paymentQrCode?: string | null;

  payoutId?: string | null;
  chequeNumber?: string | null;

  marketLogo?: string | null;
}

export interface SalePDFProps {
  marketId: string;
  documentId: string;
  saleId: string;

  market: Market;
  sale: Sale;
  lots: Lot[];
  invoices: Invoice[];

  marketLogo?: string | null;
}

export interface CartPDFProps {
  marketId: string;

  market: Market;
  documentId: string;
  customerId: string;
  allUninvoicedLotsBrought: Lot[];
  allUninvoicedLotsSold: Lot[];
  lotsBroughtBySale: { [saleId: string]: Lot[] };
  lotsSoldBySale: { [saleId: string]: Lot[] };
  extras: CartItem[];
  sales: Sale[];
  customer: Customer | null;
}

export interface ReportPDFProps {
  marketId: string;
  documentId: Report;
  saleIds: string[];
  reportData: ReportData;
}

interface DocumentSpecBase {
  id: string;
  name: string;

  requiresChequePrinter?: boolean;

  isAvailableFor(
    market: Market,
    superTypes: SuperType[],
    clientType?: ClientType
  ): boolean;
}

interface ReactPdfInvoiceDocumentSpec extends DocumentSpecBase {
  // what the objectID the is passed in relates to
  // it denotes what data is loaded and passed in as props to the template
  objectType: "invoice";
  getFilename(props: InvoicePDFProps): string;
  reactPDF: React.FC<InvoicePDFProps>;
  jsPDF?: never;
}

interface JsPdfInvoiceDocumentSpec extends DocumentSpecBase {
  objectType: "invoice";
  getFilename(props: InvoicePDFProps): string;
  reactPDF?: never;
  jsPDF: (props: InvoicePDFProps) => jsPDF;
}

interface ReactPdfSaleDocumentSpec extends DocumentSpecBase {
  objectType: "sale";
  getFilename(props: SalePDFProps): string;
  reactPDF: React.FC<SalePDFProps>;
  jsPDF?: never;
}

interface JsPdfSaleDocumentSpec extends DocumentSpecBase {
  objectType: "sale";
  getFilename(props: SalePDFProps): string;
  reactPDF?: never;
  jsPDF: (props: SalePDFProps) => jsPDF;
}

interface ReactPdfCartDocumentSpec extends DocumentSpecBase {
  objectType: "cart";
  getFilename(props: CartPDFProps): string;
  reactPDF: React.FC<CartPDFProps>;
  jsPDF?: never;
}

interface JsPdfCartDocumentSpec extends DocumentSpecBase {
  objectType: "cart";
  getFilename(props: CartPDFProps): string;
  reactPDF?: never;
  jsPDF: (props: CartPDFProps) => jsPDF;
}

interface JsPdfReportDocumentSpec extends DocumentSpecBase {
  objectType: "report";
  getFilename(props: ReportPDFProps): string;
  reactPDF?: never;
  jsPDF: (props: ReportPDFProps) => jsPDF;
}
interface ReactPdfReportDocumentSpec extends DocumentSpecBase {
  objectType: "report";
  getFilename(props: ReportPDFProps): string;
  reactPDF: React.FC<ReportPDFProps>;
  jsPDF?: never;
}

export type InvoiceDocumentSpec =
  | ReactPdfInvoiceDocumentSpec
  | JsPdfInvoiceDocumentSpec;

export type SaleDocumentSpec = ReactPdfSaleDocumentSpec | JsPdfSaleDocumentSpec;
export type CartDocumentSpec = ReactPdfCartDocumentSpec | JsPdfCartDocumentSpec;
export type ReportDocumentSpec =
  | ReactPdfReportDocumentSpec
  | JsPdfReportDocumentSpec;

export type DocumentSpec =
  | InvoiceDocumentSpec
  | SaleDocumentSpec
  | CartDocumentSpec
  | ReportDocumentSpec;

let cachedDocuments: DocumentSpec[] | null = null;

export default function getDocuments() {
  if (cachedDocuments) {
    return cachedDocuments;
  }

  let seenNames = new Set<string>();

  let documents: DocumentSpec[] = modules
    .map((module, idx) => {
      if (!module.name || !module.id) {
        console.error(`Document at index ${idx} has no name or id`, module);
        return null;
      }

      if (seenNames.has(module.id)) {
        console.error(
          `Document with id ${module.id} already exists. Not loading it again.`
        );
        return null;
      }

      return module;
    })
    .filter(Boolean) as DocumentSpec[];

  cachedDocuments = documents;

  return documents;
}
