import { classNames } from "@/data/classnames";
import { Dialog, Transition } from "@headlessui/react";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";

// Form
import { Button } from "@/components/Buttons";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { SelectField } from "./fields/SelectField";
import { TextField } from "./fields/TextField";

// Address functions
import { getCountriesList } from "@/data/countries";
import AddressFormatter from "@shopify/address";
import Autocomplete from "react-google-autocomplete";
import { useAddressFunctions } from "./useAddressFunctions";

//Interfaces
import { Address, AddressWrapper } from "../../types";

interface AddressDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  customerId: string;
  address: AddressWrapper | null;
  addressIsPrimary: boolean;
  closeOnCreate?: (address: boolean) => void;
}

const AddressSchema = Yup.object().shape({
  address1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  zip: Yup.string().required("Zip is required"),
  country: Yup.string().required("Country is required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
});

const SubmitButon = (props: {
  isValid: boolean;
  isSubmitting: boolean;
  isUpdate: boolean;
}) => {
  const { isValid, isSubmitting, isUpdate } = props;
  const { submitForm } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  };

  let isDisabled = !isValid || props.isSubmitting;

  return (
    <Button
      type="button"
      onClick={isValid ? handleSubmit : null}
      isDisabled={isDisabled}
      isLoading={isSubmitting}
      className={classNames(
        "bg-martEye-400 text-martEye-100 disabled:opacity-50"
      )}
    >
      {isUpdate ? "Update" : "Create"}
    </Button>
  );
};

export function AddressDialog(props: AddressDialogProps) {
  const {
    open,
    setOpen,
    address,
    addressIsPrimary,
    customerId,
    closeOnCreate,
  } = props;
  const cancelButtonRef = useRef(null);
  const addressFormatter = new AddressFormatter("en");

  //

  const onClose = () => {
    setOpen(false);
    setUpdated(false);
  };

  // TODO get type
  const [addressFields, setAddressFields] = useState<any>([]);
  const [updated, setUpdated] = useState<boolean>(false);
  const [country, setCountry] = useState<string>("IE");
  const countries = getCountriesList();

  const { createAddress, updateAddress } = useAddressFunctions(customerId);

  useEffect(() => {
    getFields();
    async function getFields() {
      const res = await addressFormatter.getOrderedFields(
        country.substring(0, 2)
      );
      setAddressFields(res);
    }
  }, [country]);

  const onSubmit = async (values: any) => {
    if (customerAddress?.id) {
      const response = await updateAddress(
        values,
        customerAddress.id,
        addressIsPrimary
      );

      if (response) {
        setUpdated(true);
      }
    } else {
      const response = await createAddress(values, addressIsPrimary);

      if (response) {
        setUpdated(true);

        if (closeOnCreate) {
          closeOnCreate(response);
        }
      }
    }
  };

  const customerAddress = address?.address;

  const defaultValues: Omit<Address, "id"> = {
    address1: customerAddress?.address1 || "",
    address2: customerAddress?.address2 || "",
    city: customerAddress?.city || "",
    company: customerAddress?.company || "",
    country: customerAddress?.country || "",
    firstName: customerAddress?.firstName || "",
    lastName: customerAddress?.lastName || "",
    province: customerAddress?.province || "",
    zip: customerAddress?.zip || "",
  };

  const requiredFields = useMemo(() => {
    return getRequiredFields(AddressSchema);
  }, [AddressSchema]);

  const countryOptions = useMemo(() => {
    // Convert the object
    return Object.entries(countries).map(([key, value]) => {
      return { label: value, value: key };
    });
  }, [countries]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[100]"
          initialFocus={cancelButtonRef}
          onClose={onClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-martEye-700 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left text-martEye-700 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="px-4 pb-4 pt-5  sm:p-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title
                        as="h3"
                        className="inline-flex items-center gap-x-1.5 text-xl font-bold"
                      >
                        {customerAddress?.id ? "Edit" : "Add"} Address
                      </Dialog.Title>

                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-full bg-martEye-500 p-1 text-martEye-100  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400 hover:bg-martEye-400"
                          onClick={onClose}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>

                    <div className="mt-4">
                      <Formik
                        initialValues={defaultValues}
                        onSubmit={onSubmit}
                        validationSchema={AddressSchema}
                        validateOnMount={true}
                      >
                        {(props) => {
                          const { setFieldValue } = props;

                          return (
                            <>
                              {updated && (
                                <div className="rounded-md bg-martEye-100 p-4">
                                  <div className="flex">
                                    <div className="flex-shrink-0">
                                      <CheckCircleIcon
                                        className="h-5 w-5 text-martEye-700"
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <div className="ml-3">
                                      <p className="text-sm font-medium text-martEye-700">
                                        Successfully{" "}
                                        {customerAddress?.id
                                          ? "updated"
                                          : "created"}{" "}
                                        address.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Often this address is equivalent to the postal address. Note that some countries, such as the United Kingdom, do not allow distribution of true postal addresses due to licensing restrictions.
                              https://developers.google.com/maps/documentation/places/web-service/search-find-place */}
                              <div className="my-4">
                                <Autocomplete
                                  inputAutocompleteValue="test"
                                  className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-martEye-400 sm:text-sm sm:leading-6"
                                  apiKey={
                                    process.env.NEXT_PUBLIC_GOOGLE_PLACES_API
                                  }
                                  options={{
                                    types: ["address"],
                                  }}
                                  onPlaceSelected={(place) => {
                                    // Prgramatically set the values
                                    setFieldValue(
                                      "country",
                                      getCountryShortCode(
                                        place.address_components
                                      )
                                    );
                                    setFieldValue(
                                      "address1",
                                      getAddress(
                                        place.address_components,
                                        "address1"
                                      )
                                    );
                                    setFieldValue(
                                      "address2",
                                      getAddress(
                                        place.address_components,
                                        "address2"
                                      )
                                    );
                                    setFieldValue(
                                      "province",
                                      getAddress(
                                        place.address_components,
                                        "province"
                                      )
                                    );
                                    setFieldValue(
                                      "city",
                                      getAddress(
                                        place.address_components,
                                        "city"
                                      )
                                    );
                                    setFieldValue(
                                      "zip",
                                      getAddress(
                                        place.address_components,
                                        "zip"
                                      )
                                    );
                                  }}
                                />
                              </div>
                              <Form className="flex flex-wrap gap-2">
                                {addressFields.map((fields: string[]) => (
                                  <div
                                    className="flex w-full flex-nowrap gap-x-4"
                                    key={fields.join("-")}
                                  >
                                    {fields?.map(
                                      (field: string, index: number) => {
                                        if (field === "country") {
                                          return (
                                            <div key={field} className="grow">
                                              <Field
                                                name={field}
                                                component={SelectField}
                                                options={countryOptions ?? []}
                                                label={field}
                                                type="text"
                                                required={requiredFields.includes(
                                                  field
                                                )}
                                                onChange={(value: string) => {
                                                  setCountry(value);
                                                }}
                                              />
                                            </div>
                                          );
                                        }

                                        return (
                                          <div key={field} className="grow">
                                            <Field
                                              name={field}
                                              component={TextField}
                                              label={field}
                                              type="text"
                                              required={requiredFields.includes(
                                                field
                                              )}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                ))}
                                <div className="mt-2">
                                  <SubmitButon
                                    isValid={props.isValid}
                                    isSubmitting={props.isSubmitting}
                                    isUpdate={
                                      customerAddress?.id ? true : false
                                    }
                                  />
                                </div>
                              </Form>
                            </>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

// Helper Functions

function getRequiredFields(jsonObj: any) {
  // Extract the fields object from the JSON
  const fields = jsonObj.fields;

  // Get the list of required fields from the _nodes array
  const requiredFields = jsonObj._nodes;

  // Store the required field names
  const requiredFieldNames = [];

  // Iterate over the required fields and extract their names
  for (let field of requiredFields) {
    requiredFieldNames.push(field);
  }

  // Return the array of required field names
  return requiredFieldNames;
}

export function getCountryShortCode(addressComponents: any) {
  console.log(addressComponents);

  for (let i = 0; i < addressComponents.length; i++) {
    const component = addressComponents[i];

    console.log(component);

    if (component.types.includes("administrative_area_level_1")) {
      if (component.short_name === "England") {
        return "GB-ENG";
      }
      if (component.short_name === "Scotland") {
        return "GB-SCT";
      }
      if (component.short_name === "Wales") {
        return "GB-WLS";
      }
      if (component.short_name === "Northern Ireland") {
        return "GB-NIR";
      }
    }

    if (component.types.includes("country")) {
      return component.short_name;
    }
  }
  return null; // Return null if country short code is not found
}

// TODO Sort the types

export function getAddress(addressComponents: any, part: string) {
  console.log(addressComponents);
  switch (part) {
    case "address1":
      let address1 = "";

      let street_number = addressComponents.find((item: any) =>
        item.types.includes("street_number")
      );
      if (street_number) {
        address1 += street_number.long_name;
      }

      // farm name
      let premise = addressComponents.find((item: any) =>
        item.types.includes("premise")
      );
      if (premise) {
        address1 += " " + premise.long_name;
      }

      let route = addressComponents.find((item: any) =>
        item.types.includes("route")
      );
      if (route) {
        address1 += " " + route.long_name;
      }

      return address1;

    case "province":
      const province = addressComponents.find((item: any) =>
        item.types.includes("administrative_area_level_1")
      );

      const provinceName = province ? province.long_name : "";

      return provinceName;

    case "address2":
      let address2 = "";

      let administrative_area_level_2 = addressComponents.find(
        (item: any) =>
          item.types.includes("administrative_area_level_2") ||
          item.types.includes("sublocality")
      );

      if (administrative_area_level_2) {
        address2 += administrative_area_level_2.long_name;
      }

      return address2;

    case "city":
      let locality = addressComponents.find(
        (item: any) =>
          item.types.includes("postal_town") || item.types.includes("locality")
      );

      return locality?.long_name ?? null;

    case "zip":
      let zip = addressComponents.find((item: any) =>
        item.types.includes("postal_code")
      );

      return zip?.long_name ?? null;

    default:
      return null;
  }

  return null;
}
